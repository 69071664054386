import { createSlice,createAsyncThunk,createEntityAdapter } from "@reduxjs/toolkit";

import promotionsService from "./service";

const promotionAdapter = createEntityAdapter();

export const _get = createAsyncThunk("promotions/get", async(params, {rejectWithValue}) => {
    try {
        const response = await promotionsService.get(params);
        return response
    } catch (error) {
         const message = error.response.data
       
        return rejectWithValue(message);
    }
});

export const _edit = createAsyncThunk("promotions/edit", async(data, {rejectWithValue}) => {
    try {
        const response = await promotionsService.edit(data);
       
        return response

    } catch (error) {
         const message = error.response.data
     
        return rejectWithValue(message);
    }
});


export const _create = createAsyncThunk("promotions/create", async(data, {rejectWithValue}) => {
    try {
        const response = await promotionsService.create(data);
       
        return response

    } catch (error) {
         const message = error.response.data
     
        return rejectWithValue(message);
    }
});


const initialState = promotionAdapter.getInitialState({
    total: 0,
    page: 1,
    pages: 1,
    per_page: 10,
    status: 'idle',
    error: null,
    editStatus:'idle',
    editError:null,
    createStatus:'idle',
    createError:null
});


const promotions = createSlice({
    name:"promotions",
    initialState,
    reducers:{
        _reset: (state) => {
            state.status = 'idle';
            state.error = "";
            state.editStatus = "idle";
            state.editError = "";
            state.createStatus = "idle";
            state.createError = ""
        }
    },
    extraReducers:(builder) => {
        builder
        .addCase(_get.pending, (state) => {
            state.status = 'pending'
        })
        .addCase(_get.fulfilled, (state, action) => {
            state.status = 'fulfilled';
            promotionAdapter.setAll(state, action.payload.items);
            state.total = action.payload.total;
            state.page = action.payload.page;
            state.pages = action.payload.pages;
            state.per_page = action.payload.per_page;
        })
        .addCase(_get.rejected, (state,action) => {
            state.status = "rejected"
            state.error = action.payload.errorcode
        })
        .addCase(_edit.pending, (state) => {
            state.editStatus = 'pending'
        })
        .addCase(_edit.fulfilled, (state,action) => {
            state.editStatus = "fulfilled";
            promotionAdapter.upsertOne(state,action.payload)
        })
        .addCase(_edit.rejected,(state,action)=>{
            state.editStatus = "rejected"
            state.editError = action.payload
        })
        .addCase(_create.pending, (state) => {
            state.createStatus = 'pending'
        })
        .addCase(_create.fulfilled, (state,action) => {
            state.createStatus = "fulfilled";
            promotionAdapter.addOne(state,action.payload)
        })
        .addCase(_create.rejected,(state,action)=>{
            state.createStatus = "rejected"
            state.createStatus = action.payload
        })
    }
})

export const {_reset} = promotions.actions;
export default promotions.reducer

export const {
    selectAll: selectAllUsers,
    selectById: selectUserById,
    selectIds: selectUserIds,
   
} = promotionAdapter.getSelectors(state => state.promotions);
