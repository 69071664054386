import { createSlice,createAsyncThunk,createEntityAdapter } from "@reduxjs/toolkit";

import airdropsService from "./service";

const airdropAdapter = createEntityAdapter();

export const _get = createAsyncThunk("airdrop/get", async(params, {rejectWithValue}) => {
    try {
        const response = await airdropsService.get(params);
        return response
    } catch (error) {
         const message = error.response.data
       
        return rejectWithValue(message);
    }
});

export const _participations = createAsyncThunk("airdrop/participations", async(params, {rejectWithValue}) => {
    try {
        const response = await airdropsService.participations(params);
        return response
    } catch (error) {
         const message = error.response.data
       
        return rejectWithValue(message);
    }
});

export const _edit = createAsyncThunk("airdrop/edit", async(data, {rejectWithValue}) => {
    try {
        const response = await airdropsService.edit(data);
       
        return response

    } catch (error) {
         const message = error.response.data
     
        return rejectWithValue(message);
    }
});


export const _create = createAsyncThunk("airdrop/create", async(data, {rejectWithValue}) => {
    try {
        const response = await airdropsService.create(data);
       
        return response

    } catch (error) {
         const message = error.response.data
     
        return rejectWithValue(message);
    }
});

export const _changeStatus = createAsyncThunk("airdrop/change", async(data, {rejectWithValue}) => {
    try {
        const response = await airdropsService.editParticipations(data);
       
        return response

    } catch (error) {
         const message = error.response.data
     
        return rejectWithValue(message);
    }
});


const initialState = airdropAdapter.getInitialState({
    total: 0,
    page: 1,
    pages: 1,
    per_page: 10,
    participationTotal: 0,
    participationPage: 1,
    participationPages: 1,
    participationPer_page: 10,
    status: 'idle',
    error: null,
    editStatus:'idle',
    editError:null,
    createStatus:'idle',
    createError:null,
    participationsStatus:'idle',
    participationsError:null,
    changeStatus:"idle",
    changeError:null
});


const airdrops = createSlice({
    name:"airdrops",
    initialState,
    reducers:{
        _reset: (state) => {
            state.status = 'idle';
            state.error = "";
            state.editStatus = "idle";
            state.editError = "";
            state.createStatus = "idle";
            state.createError = "";
            state.participationsStatus = 'idle';
            state.participationsError = "error";
            state.changeStatus = "idle";
            state.changeError = ""
        }
    },
    extraReducers:(builder) => {
        builder
        .addCase(_get.pending, (state) => {
            state.status = 'pending'
        })
        .addCase(_get.fulfilled, (state, action) => {
            state.status = 'fulfilled';
            airdropAdapter.setAll(state, action.payload.items);
            state.total = action.payload.total;
            state.page = action.payload.page;
            state.pages = action.payload.pages;
            state.per_page = action.payload.per_page;
        })
        .addCase(_get.rejected, (state,action) => {
            state.status = "rejected"
            state.error = action.payload.errorcode
        })
        .addCase(_edit.pending, (state) => {
            state.editStatus = 'pending'
        })
        .addCase(_edit.fulfilled, (state,action) => {
            state.editStatus = "fulfilled";
            airdropAdapter.upsertOne(state,action.payload)
        })
        .addCase(_edit.rejected,(state,action)=>{
            state.editStatus = "rejected"
            state.editError = action.payload
        })
        .addCase(_create.pending, (state) => {
            state.createStatus = 'pending'
        })
        .addCase(_create.fulfilled, (state,action) => {
            state.createStatus = "fulfilled";
            airdropAdapter.addOne(state,action.payload)
        })
        .addCase(_create.rejected,(state,action)=>{
            state.createStatus = "rejected"
            state.createError = action.payload
        })
        .addCase(_participations.pending, (state) => {
            state.participationsStatus = 'pending'
        })
        .addCase(_participations.fulfilled, (state,action) => {
            state.participationsStatus = "fulfilled";
            state.participationTotal = action.payload.total;
            state.participationPage = action.payload.page;
            state.participationPages = action.payload.pages;
            state.participationPer_page = action.payload.per_page;
            airdropAdapter.setAll(state, action.payload.items);
        })
        .addCase(_participations.rejected,(state,action)=>{
            state.participationsStatus = "rejected"
            state.participationsError = action.payload
        })
        .addCase(_changeStatus.pending, (state) => {
            state.changeStatus = 'pending'
        })
        .addCase(_changeStatus.fulfilled, (state,action) => {
            state.changeStatus = "fulfilled";
            airdropAdapter.upsertOne(state, action.payload);
        })
        .addCase(_changeStatus.rejected,(state,action)=>{
            state.changeStatus = "rejected"
            state.changeError = action.payload
        })
    }
})

export const {_reset} = airdrops.actions;
export default airdrops.reducer

export const {
    selectAll: selectAllAirdrops,
 
    selectById: selectAirdropById,
    selectIds: selectUserIds,
   
} = airdropAdapter.getSelectors(state => state.airdrops);
