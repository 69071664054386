import { useEffect } from "react"
import { useParams } from "react-router-dom"
import Wrapper from "../../../components/Wrapper"
import PromotionEditContainer from "../../../containers/Promotions/Edit"
import {useSelectPromotionById} from "../../../stores/promotions/hooks"
import {partners} from "../../../stores/partners/actions"
import { promotions } from "../../../stores/promotions/actions"
import { useData as usePromotionsData } from "../../../stores/promotions/hooks"
import {useData} from "../../../stores/partners/hooks"
const PromotionEdit = () => {
  const {id} = useParams();
  const selectedPromotion = useSelectPromotionById(id)
  const data = useData()
  const promotionsData = usePromotionsData();
  useEffect(()=>{
    partners.get()
    if(!promotionsData.length > 0 ){
      promotions.get()
  }
  },[])
  useEffect(()=>{
   
  },[promotionsData])

  return (
    <Wrapper title="Promosyonlar" path="Anasayfa/Promosyonlar/Oluştur">
        <PromotionEditContainer promotion={selectedPromotion} data={data} />
    </Wrapper>
  )
}
export default PromotionEdit