import store from "..";
import {_reset,_login,_logout}  from ".";

export const login = {
    reset:() => {
        store.dispatch(_reset())
    },
    post: (data) => {
        store.dispatch(_login(data))
    },
    logout:() => {
        store.dispatch(_logout())
    }
}