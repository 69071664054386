import {useSelector} from "react-redux";
import { selectAllUsers, selectUserById } from ".";
export const useStatus = () => useSelector(state=> state.promotions.status);
export const useError = () => useSelector(state=> state.promotions.error);
export const useData = () => useSelector(selectAllUsers);
export const useEditStatus = () => useSelector(state=> state.promotions.editStatus);
export const useEditError = () => useSelector(state=> state.promotions.editError);
export const useCreateStatus = () => useSelector(state=> state.promotions.createStatus);
export const useCreateError = () => useSelector(state=> state.promotions.createError);
export const useSelectPromotionById = (id) => useSelector(state => selectUserById(state,id))
export const usePage = () => useSelector(state=>state.promotions.page);
export const usePages = () => useSelector(state=>state.promotions.pages);
export const useTotal = () => useSelector(state=>state.promotions.total);
export const usePerPage = () => useSelector(state=>state.promotions.per_page);