import Table from "../../../components/Table"

const PromotionCodesContainer = ({ data,columns,totalPages,pageNumber,perPage,total,nextPageFn,prevPageFn }) => {
 

  return (
    <div class="row g-5 g-xl-8">
     <Table data={data} columns={columns} totalPages={totalPages} pageNumber={pageNumber} perPage={perPage} total={total} nextPageFn={nextPageFn} prevPageFn={prevPageFn} />
    </div>
  );
};
export default PromotionCodesContainer;
