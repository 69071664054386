import { useEffect } from "react";
import PromotionCard from "../../../components/promotion-card";
import { promotions } from "../../../stores/promotions/actions";
import {useEditStatus,useEditError} from "../../../stores/promotions/hooks"
import { toast } from "sonner";

const PromotionListContainer = ({ data }) => {
  const editStatus = useEditStatus();
  const editError = useEditError();

  useEffect(()=>{
    if(editStatus === "fulfilled"){
      toast.success("Promosyon Güncellendi")
    }else if(editStatus === "rejected"){
      toast.error(editError)
    }
    return () => {
      promotions.reset();
    }
  },[editStatus])

  const disabledPromotion = (id) => {
    promotions.edit({
      id:id,
      enabled:false
    })
  }

  const enabledPromotion = (id) => {
    promotions.edit({
      id:id,
      enabled:true
    })
  }

  return (
    <div class="row g-5 g-xl-8">
      {data.map((promo, index) => (
        <div key={index} class="col-xl-6">
          <PromotionCard title={promo.title} bg={promo.cover_image} desc={promo.description} endDate={promo.end_date} logo={promo.brand_image} needBalance={promo.require_balance && true} needStake={promo.require_stake && true} partner="Partner Name" startDate={promo.start_date} enabled={promo.enabled} disabledFn={() => disabledPromotion(promo.id)} enabledFn={() => enabledPromotion(promo.id)}  id={promo.id}  />
        </div>
      ))}
    </div>
  );
};
export default PromotionListContainer;
