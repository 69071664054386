import { createSlice,createAsyncThunk,createEntityAdapter } from "@reduxjs/toolkit";

import promocodesService from "./service";

const promocodesAdapter = createEntityAdapter();

export const _get = createAsyncThunk("promocodes/get", async(params, {rejectWithValue}) => {
    try {
        const response = await promocodesService.get(params);
        return response
    } catch (error) {
         const message = error.response.data
       
        return rejectWithValue(message);
    }
});

export const _edit = createAsyncThunk("promocodes/edit", async(data, {rejectWithValue}) => {
    try {
        const response = await promocodesService.edit(data);
       
        return response

    } catch (error) {
         const message = error.response.data
     
        return rejectWithValue(message);
    }
});



const initialState = promocodesAdapter.getInitialState({
    total: 0,
    page: 1,
    pages: 1,
    per_page: 10,
    status: 'idle',
    error: null,
    editStatus:'idle',
    editError:null,
    createStatus:'idle',
    createError:null
});


const promocodes = createSlice({
    name:"promocodes",
    initialState,
    reducers:{
        _reset: (state) => {
            state.status = 'idle';
            state.error = "";
            state.editStatus = "idle";
            state.editError = "";
            state.createStatus = "idle";
            state.createError = ""
        }
    },
    extraReducers:(builder) => {
        builder
        .addCase(_get.pending, (state) => {
            state.status = 'pending'
        })
        .addCase(_get.fulfilled, (state, action) => {
            state.status = 'fulfilled';
            promocodesAdapter.setAll(state, action.payload.items);
            state.total = action.payload.total;
            state.page = action.payload.page;
            state.pages = action.payload.pages;
            state.per_page = action.payload.per_page;
        })
        .addCase(_get.rejected, (state,action) => {
            state.status = "rejected"
            state.error = action.payload.errorcode
        })
        .addCase(_edit.pending, (state) => {
            state.editStatus = 'pending'
        })
        .addCase(_edit.fulfilled, (state,action) => {
            state.editStatus = "fulfilled";
            promocodesAdapter.upsertOne(state,action.payload)
        })
        .addCase(_edit.rejected,(state,action)=>{
            state.editStatus = "rejected"
            state.editError = action.payload
        })
       
    }
})

export const {_reset} = promocodes.actions;
export default promocodes.reducer

export const {
    selectAll: selectAllPromocodes,
    selectById: selectPromocodesById,

   
} = promocodesAdapter.getSelectors(state => state.promocodes);
