import {useSelector} from "react-redux";
import { selectAllAirdrops,selectAirdropById} from ".";

export const useStatus = () => useSelector(state=> state.airdrops.status);
export const useError = () => useSelector(state=> state.airdrops.error);
export const useData = () => useSelector(selectAllAirdrops);
export const useAirdropById = (userId) => useSelector(state => selectAirdropById(state,userId))
export const useEditStatus = () => useSelector(state=> state.airdrops.editStatus);
export const useEditError = () => useSelector(state=> state.airdrops.editError);
export const useCreateStatus = () => useSelector(state=> state.airdrops.createStatus);
export const useCreateError = () => useSelector(state=> state.airdrops.createError);
export const useParticipationsStatus = () => useSelector(state=> state.airdrops.participationsStatus);
export const useParticipationsError = () => useSelector(state=> state.airdrops.participationsError);
export const useParticipationsData = () => useSelector(selectAllAirdrops);
export const useParticipationsChangeStatus = () => useSelector(state => state.airdrops.changeStatus);
export const useParticipationsChangeError = () => useSelector(state => state.airdrops.changeError);

export const usePage = () => useSelector(state=>state.airdrops.page);
export const usePages = () => useSelector(state=>state.airdrops.pages);
export const useTotal = () => useSelector(state=>state.airdrops.total);
export const usePerPage = () => useSelector(state=>state.airdrops.per_page);

export const useParticipationsPage = () => useSelector(state=>state.airdrops.participationPage);
export const useParticipationsPages = () => useSelector(state=>state.airdrops.participationPages);
export const useParticipationsTotal = () => useSelector(state=>state.airdrops.participationTotal);
export const useParticipationsPerPage = () => useSelector(state=>state.airdrops.participationPer_page);
