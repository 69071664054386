import { useEffect } from "react"
import Wrapper from "../../../components/Wrapper"

import { users } from "../../../stores/users/actions"
import { useUserById,useData} from "../../../stores/users/hooks"
import { useParams } from "react-router-dom"

import UserDetailContainer from "../../../containers/Users/Detail"

const UserDetail = () => {
    const {id} = useParams()
    const user = useUserById(id)
    const data = useData()
    useEffect(() => {
        users.get({id:id});
    }, [])


  return (
        <Wrapper title="Kullanıcılar" path={`Anasayfa/Kullanıcılar/${user?.first_name} ${user?.last_name}`} >
            <UserDetailContainer data={user}/>
        </Wrapper>
  )
}
export default UserDetail
