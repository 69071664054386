import { createBrowserRouter } from "react-router-dom";

import AuthLayout from "../layouts/AuthLayout";
import MainLayout from "../layouts/MainLayout";

import Login from "../pages/Login";
import Home from "../pages/Home"
import Users from "../pages/Users";
import User from "../pages/Users/Detail";
import Partners from "../pages/Partners";
import PromotionList from "../pages/Promotions/List";
import PromotionCreate from "../pages/Promotions/Create";
import AirdropList from "../pages/Airdrops/List";
import Airdrop from "../pages/Airdrops/Edit"
import AirdropCreate from "../pages/Airdrops/Create";
import AirdropParticipations from "../pages/Airdrops/Participations";
import StakeOptions from "../pages/Stakes/Options";
import StakeParticipations from "../pages/Stakes";
import Transactions from "../pages/Transactions";
import Options from "../pages/Options";
import PromotionEdit from "../pages/Promotions/Edit";
import PromotionCodes from "../pages/Promotions/Codes"
import PromotionParticipations from "../pages/Promotions/Participations";


const routes = createBrowserRouter([
    {
        element:<AuthLayout/>,
        children:[
            {
                path:"/login",
                element:<Login/>
            }
        ]
    },
    {
        element:<MainLayout/>,
        children:[
            {
                path:"/",
                element:<Home/>
            },
            {
                path:"/users",
                element:<Users/>
            },
            {
                path:"/users/:id",
                element:<User/>
            },
            {
                path:"/partners",
                element:<Partners/>
            }
            ,
            {
                path:"/promotions/list",
                element:<PromotionList/>
            },
            {
                path:"/promotions/codes",
                element:<PromotionCodes/>
            },
            {
                path:"/promotions/participations",
                element:<PromotionParticipations/>
            },
            {
                path:"/promotions/create",
                element:<PromotionCreate/>
            },
            {
                path:"/promotions/:id",
                element:<PromotionEdit/>
            },
            {
                path:"/airdrops/list",
                element:<AirdropList/>
            },
            {
                path:"/airdrops/:id",
                element:<Airdrop/>
            },
            {
                path:"/airdrops/create",
                element:<AirdropCreate/>
            },
            {
                path:"/airdrops/participations",
                element:<AirdropParticipations/>
            },
            {
                path:"/stakes/options",
                element:<StakeOptions/>
            },
            {
                path:"/stakes/list",
                element:<StakeParticipations/>
            },
            {
                path:"/stakes/:id",
                element:<StakeParticipations/>
            },
            {
                path:"/transactions",
                element:<Transactions/>
            },
            {
                path:"/options",
                element:<Options/>
            }
        ]
    },
    
])

export default routes;