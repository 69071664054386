import instance from '../../utils/api/instance'

const participations = async(params) => {
    const response = await instance.get("airdrop-participations",{params:params})
    if(response.status === 200){
        return response.data;
    }

    return Promise.reject(response.data);
}

const editParticipations = async(data) => {
    const response = await instance.patch("airdrop-participations",data)
    if(response.status === 200){
        return response.data;
    }

    return Promise.reject(response.data);
}



const airdropsService = {

    participations,
    editParticipations
};

export default airdropsService;