import React from 'react';
import ReactDOM from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';
import {Provider} from 'react-redux';
import store from './stores';
import { Toaster } from 'sonner';
import router from './routes';
import './assets/css/style.css';
import './assets/plugins/global/plugins.css'
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';
import moment from "moment";
import "moment/locale/tr";
const root = ReactDOM.createRoot(document.getElementById('root'));
moment.locale("tr");
root.render(
 
  <Provider store={store}>
    <Toaster richColors />
    <RouterProvider router={router}/>
  </Provider>
  
);

