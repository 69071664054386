import CreatePartner from "../modals/create-partner";
import AdjustBalance from "../modals/adjust-balance";
import ResetApi from "../modals/reset-api";
import CreateStakeOption from "../modals/create-stake-option";
import EditStakeOption from "../modals/edit-stake-option";
import DetailStake from "../modals/detail-stake";
import UpdateUser from "../modals/update-user";
import CreateOption from "../modals/create-option";
import EditOption from "../modals/edit-option";
import EditPromoCode from "../modals/edit-promo-code";
import DetailTransaction from "../modals/detail-transaction"

export const modal = [
  {
    name: "modal.create-merchant",
    element: CreatePartner,
  },
  {
    name: "modal.adjust-balance",
    element: AdjustBalance,
  },
  {
    name: "modal.reset-api",
    element: ResetApi
  },
  {
    name:"modal.create-stake-option",
    element:CreateStakeOption
  },
  {
    name:"modal.edit-stake-option",
    element:EditStakeOption
  },
  {
    name:"modal.detail-stake",
    element:DetailStake
  },
  {
    name:"modal.update-user",
    element:UpdateUser
  },
  {
    name:"modal.create-option",
    element:CreateOption
  },
  {
    name:"modal.edit-option",
    element:EditOption
  },
  {
    name:"modal.edit-promo-code",
    element:EditPromoCode
  },
  {
    name:"modal.detail-transaction",
    element:DetailTransaction
  }
];
