import moment from "moment";
import { Menu } from "@headlessui/react";
import { Link } from "react-router-dom";
const PromotionCard = ({
  logo,
  title,
  partner,
  desc,
  startDate,
  endDate,
  needStake,
  needBalance,
  bg,
  enabled,
  disabledFn,
  enabledFn,
  id
}) => {
  return (
    <div className="card mb-5 mb-xl-8">
      <div className="card-body pb-0">
        <div className="d-flex align-items-center mb-5">
          <div className="d-flex align-items-center flex-grow-1">
            <div className="symbol symbol-45px me-5">
              <img src={logo} alt="" />
            </div>

            <div className="d-flex flex-column">
              <a
                href="#"
                className="text-gray-900 text-hover-primary fs-6 fw-bold"
              >
                {title}
              </a>
              <span className="text-gray-400 fw-bold">{partner}</span>
            </div>
          </div>

          <div className="my-0">
            <Menu as="div" style={{ position: "relative" }}>
              <Menu.Button
                type="button"
                className="btn btn-sm btn-icon btn-color-primary btn-active-light-primary"
                data-kt-menu-trigger="click"
                data-kt-menu-placement="bottom-end"
              >
                <i className="ki-duotone ki-pencil fs-6">
                  <span className="path1"></span>
                  <span className="path2"></span>
                  <span className="path3"></span>
                  <span className="path4"></span>
                </i>
              </Menu.Button>

              <Menu.Items
                className="themeChanger menu menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-semibold w-200px"
                data-kt-menu="true"
              >
                <div className="menu-item px-3">
                  <div className="menu-content fs-6 text-dark fw-bold px-3 py-4">
                    Kampanya İşlemleri
                  </div>
                </div>

                <div className="separator mb-3 opacity-75"></div>

                <Menu.Item as="div" className="menu-item px-3">
                  <Link to={`/promotions/${id}`} className="menu-link px-3">Düzenle</Link>
                </Menu.Item>

                {enabled && (
                  <Menu.Item as="div" className="menu-item px-3">
                    <span onClick={disabledFn} className="menu-link px-3">
                      Tamamen Sonlandır
                    </span>
                  </Menu.Item>
                )}

                {!enabled && (
                  <Menu.Item as="div" className="menu-item px-3">
                    <span onClick={enabledFn} className="menu-link px-3">
                      Yayınla
                    </span>
                  </Menu.Item>
                )}

                {
                  /*
                  *  <Menu.Item as="div" className="menu-item px-3">
                  <span className="menu-link px-3">Katılımları Görüntüle</span>
                </Menu.Item>
                  * */
                }

                <div className="separator mt-3 opacity-75"></div>
              </Menu.Items>
            </Menu>
          </div>
        </div>

        <div className="mb-5">
          <div
            className="bgi-no-repeat bgi-size-cover rounded min-h-250px mb-5"
            style={{ backgroundImage: `url(${bg})` }}
          ></div>

          <div className="text-gray-800 mb-5">{desc}</div>
        </div>

        <div className="separator mb-4"></div>
        <div className="d-flex align-items-center mb-5">
          {!enabled && (
            <a
              href="#"
              className="btn btn-sm btn-light-danger btn-color-danger btn-active-light-danger px-4 py-2 me-4"
            >
              Pasif
            </a>
          )}
          {enabled && (
            <a
              href="#"
              className="btn btn-sm btn-light-success btn-color-success btn-active-light-success px-4 py-2 me-4"
            >
              Aktif
            </a>
          )}
          <a
            href="#"
            className="btn btn-sm btn-light btn-color-success btn-active-light-success px-4 py-2 me-4"
          >
            <i className="ki-duotone ki-calendar fs-2">
              <span className="path1"></span>
              <span className="path2"></span>
              <span className="path3"></span>
            </i>
            {moment(startDate).format("L")}{" "}
          </a>
          <a
            href="#"
            className="btn btn-sm btn-light btn-color-danger btn-active-light-danger px-4 py-2 me-4"
          >
            <i className="ki-duotone ki-calendar fs-2">
              <span className="path1"></span>
              <span className="path2"></span>
              <span className="path3"></span>
            </i>
            {moment(endDate).format("L")}
          </a>

          {needStake && (
            <a
              href="#"
              className="btn btn-sm btn-light btn-color-primary btn-active-light-primary px-4 py-2 me-4"
            >
              <i className="ki-duotone ki-dollar fs-2">
                <span className="path1"></span>
                <span className="path2"></span>
                <span className="path3"></span>
              </i>{" "}
              Stake Gerekli
            </a>
          )}
          {needBalance && (
            <a
              href="#"
              className="btn btn-sm btn-light btn-color-info btn-active-light-info px-4 py-2 me-4"
            >
              <i className="ki-duotone ki-wallet fs-2">
                <span className="path1"></span>
                <span className="path2"></span>
                <span className="path3"></span>
              </i>{" "}
              Bakiye Gerekli{" "}
            </a>
          )}
        </div>
      </div>
    </div>
  );
};
export default PromotionCard;
