import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from "@reduxjs/toolkit";

import optionsService from "./service";

const optionsAdapter = createEntityAdapter();

export const _get = createAsyncThunk(
  "options/get",
  async (_, { rejectWithValue }) => {
    try {
      const response = await optionsService.get();
      return response;
    } catch (error) {
      const message = error.response.data;

      return rejectWithValue(message);
    }
  }
);

export const _create = createAsyncThunk(
  "options/create",
  async (data, { rejectWithValue }) => {
    try {
      const response = await optionsService.create(data);
      return response;
    } catch (error) {
      const message = error.response.data;

      return rejectWithValue(message);
    }
  }
);


export const _edit = createAsyncThunk(
  "options/edit",
  async (data, { rejectWithValue }) => {
    try {
      const response = await optionsService.edit(data);
      return response;
    } catch (error) {
      const message = error.response.data;

      return rejectWithValue(message);
    }
  }
);

const initialState = optionsAdapter.getInitialState({
  optionsData: [],
  listStatus: "idle",
  listError: null,
  createOptionStatus: "idle",
  createOptionError: null,
  editOptionStatus:"idle",
  editOptionError:null,
  
});

const options = createSlice({
  name: "options",
  initialState,
  reducers: {
    _reset: (state) => {
   
      state.listStatus = "idle";
      state.listError = null;
      state.createOptionStatus = "idle";
      state.createOptionError = null;
      state.editOptionStatus = "idle";
      state.editOptionError = null;
     
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(_get.pending, (state) => {
        state.listStatus = "pending";
      })
      .addCase(_get.fulfilled, (state, action) => {
        state.listStatus = "fulfilled";
        optionsAdapter.setAll(state, action.payload);
        state.optionsData = action.payload
      })
      .addCase(_get.rejected, (state, action) => {
        state.listStatus = "rejected";
        state.listError = action.payload.errorcode;
      })
      .addCase(_create.pending, (state) => {
        state.createOptionStatus = "pending";
      })
      .addCase(_create.fulfilled, (state, action) => {
        state.createOptionStatus = "fulfilled";
        optionsAdapter.setOne(state, action.payload);
      
      })
      .addCase(_create.rejected, (state, action) => {
        state.createOptionStatus = "rejected";
        state.createOptionError = action.payload.errorcode;
      })
      .addCase(_edit.pending, (state) => {
        state.editOptionStatus = "pending";
      })
      .addCase(_edit.fulfilled, (state, action) => {
        state.editOptionStatus = "fulfilled";
        optionsAdapter.upsertOne(state, action.payload);
      
      })
      .addCase(_edit.rejected, (state, action) => {
        state.editOptionStatus = "rejected";
        state.editOptionError = action.payload.errorcode;
      })
     
  },
});

export const { _reset } = options.actions;
export default options.reducer;

export const {
  selectAll: selectAllOptions,

  selectById: selectOptionsById,
} = optionsAdapter.getSelectors((state) => state.options);
