export const MENU = [
    {
        path:"/",
        title:"Anasayfa",
        icon:<i className="ki-duotone ki-home-3 fs-2">
        <span className="path1"></span>
        <span className="path2"></span>
      </i>
    },
    {
        path:"/users",
        title:"Kullanıcılar",
        icon: <i className="ki-duotone ki-user fs-2">
        <span className="path1"></span>
        <span className="path2"></span>
      </i>
    },
    {
      path:"/transactions",
      title:"Transferler",
      icon: <i className="ki-duotone ki-bank fs-2">
      <span className="path1"></span>
      <span className="path2"></span>
    </i>
  },
  {
    path:"/options",
    title:"Opsiyonlar",
    icon: <i className="ki-duotone ki-gear fs-2">
    <span className="path1"></span>
    <span className="path2"></span>
  </i>
},
    {
        path:"/partners",
        title:"İş Ortakları",
        icon: <i className="ki-duotone ki-office-bag fs-2">
        <span className="path1"></span>
        <span className="path2"></span>
        <span className="path3"></span>
        <span className="path4"></span>
      </i>
    },
    {
        path:"/promotions",
        title:"Promosyonlar",
        icon: <i className="ki-duotone ki-like-2 fs-2">
        <span className="path1"></span>
        <span className="path2"></span>
        </i>,
        children:[
            {
                path:"/promotions/list",
                title:"Listele/Düzenle"
            },
            {
                path:"/promotions/create",
                title:"Oluştur"
            },
            {
                path:"/promotions/participations",
                title:"Katılımlar"
            },
            {
              path:"/promotions/codes",
              title:"Promosyon Kodları"
          }
        ]
    },
    {
        path:"/airdrops",
        title:"Airdrop",
        icon: <i className="ki-duotone ki-cup fs-2">
        <span className="path1"></span>
        <span className="path2"></span>
      </i>,
      children:[
        {
            path:"/airdrops/list",
            title:"Kampanyalar"
        },
        {
            path:"/airdrops/create",
            title:"Kampanya Oluştur"
        },
        {
            path:"/airdrops/participations",
            title:"Onay Bekleyenler"
        }
      ]
    },
    {
        path:"/stakes",
        title:"Stake",
        icon: <i className="ki-duotone ki-rocket fs-2">
        <span className="path1"></span>
        <span className="path2"></span>
      </i>,
      children:[
        {
            path:"/stakes/list",
            title:"Listele"
        },
        {
            path:"/stakes/options",
            title:"Opsiyonlar"
        }
      ]
    }
]