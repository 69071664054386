import { useEffect } from "react";
import { promocodes } from "../../stores/promocodes/actions";
import { useEditStatus, useEditError } from "../../stores/promocodes/hooks";
import { Formik, Form } from "formik";
import { editPromoCodeScheme } from "../../validations";
import Input from "../../components/Input";
import useErrorCode from "../../hooks/useErrorCode";
const EditPromoCode = ({ destroy,modalData }) => {
  const { e } = useErrorCode();
  const status = useEditStatus();
  const error = useEditError();

  const escFunction = (e) => {
    if (e.key === "Escape") {
      destroy();
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", escFunction, false);

    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, [escFunction]);

  useEffect(() => {
    return () => {
      promocodes.reset();
    };
  }, []);

  return (
    <div className="form" id="kt_modal_add_customer_form">
      <div className="modal-header" id="kt_modal_add_customer_header">
        <h2 className="fw-bold">Promosyon Kodu Düzenle</h2>

        <div
          onClick={destroy}
          id="kt_modal_add_customer_close"
          className="btn btn-icon btn-sm btn-active-icon-primary"
        >
          <i className="ki-duotone ki-cross fs-1">
            <span className="path1"></span>
            <span className="path2"></span>
          </i>
        </div>
      </div>

      <Formik
        validationSchema={editPromoCodeScheme}
        initialValues={{
          remaining_claims:modalData.remaining_claims,
        
        }}
        onSubmit={(values) => {
          promocodes.edit({
            id:modalData.id,
            remaining_claims: values.remaining_claims
          });
        }}
      >
        <Form>
          <div className="modal-body py-10 px-lg-17">
            {status === "rejected" && (
              <div className="alert alert-danger d-flex align-items-center px-5">
                <div className="d-flex flex-column">
                  <h4 className="mb-1 text-danger">Hata!</h4>

                  <span>{e(error)}</span>
                </div>
              </div>
            )}

            {status === "fulfilled" && (
              <div className="alert alert-success d-flex align-items-center px-5">
                <div className="d-flex flex-column">
                  <h4 className="mb-1 text-success">Başarılı!</h4>

                  <span>{"Promosyon Kodu Güncellendi"}</span>
                </div>
              </div>
            )}
            <div
              className="scroll-y me-n7 pe-7"
              id="kt_modal_add_customer_scroll"
             
            >
              <div className="fv-row mb-7">
                <label className="required fs-6 fw-semibold mb-2">Katılımcı Sayısı</label>

                <Input name="remaining_claims" placeholder="Katılımcı Sayısı" />
              </div>
             
             
             
          
            </div>
          </div>

          <div className="modal-footer flex-center">
            <button
              onClick={destroy}
              type="reset"
              id="kt_modal_add_customer_cancel"
              className="btn btn-light me-3"
            >
              İptal
            </button>

            <button
              type="submit"
              disabled={status === "pending"}
              id="kt_modal_add_customer_submit"
              className="btn btn-primary"
            >
              {status === "pending" ? (
                <span style={{ display: "block" }} className="indicator-progress">
                  Lütfen Bekleyin...
                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
              ) : (
                <span className="indicator-label">Düzenle</span>
              )}
            </button>
          </div>
        </Form>
      </Formik>
    </div>
  );
};
export default EditPromoCode;
