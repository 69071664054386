import { useEffect,useState } from "react"
import Wrapper from "../../../components/Wrapper"
import { toast } from "sonner"
import ParticipationsContainer from "../../../containers/Airdrops/Participations"
import { airdrops } from "../../../stores/airdropParticipations/actions"
import { useSearchParams } from "react-router-dom";
import {useDebounce} from "use-debounce";
import {useParticipationsData,useParticipationsChangeStatus,useParticipationsPage,useParticipationsPages,useParticipationsPerPage,useParticipationsTotal} from "../../../stores/airdropParticipations/hooks";
const Participations = () => {
  let [searchParams, setSearchParams] = useSearchParams();
  const [searchQuery, setSearchQuery] = useState("");
  const [debouncedSearchQuery] = useDebounce(searchQuery, 500);
  const data = useParticipationsData();
  const status = useParticipationsChangeStatus();
  const page = useParticipationsPage();
  const pages = useParticipationsPages();
  const perPage = useParticipationsPerPage();
  const total = useParticipationsTotal();

  const [lastChangedId,setLastChangedId] = useState();

  const handleNextPage = (page) => {
    setSearchParams({page:page+1})
  }

  const handlePrevPage = (page) => {
    setSearchParams({page:page-1})
  }

  useEffect(()=>{
    airdrops.participations({page:searchParams.get("page")})
    return () => {
      airdrops.reset();
    }
  },[searchParams])

  useEffect(() => {
    airdrops.participations({search:debouncedSearchQuery})
  }, [debouncedSearchQuery]);

  const change = (id,status) => {
    setLastChangedId(id);
    airdrops.changeStatus({
        id:id,
        status:status
    })
  }

  useEffect(()=>{
    if(status === "pending"){
      toast.loading("Güncelleniyor...")
    }
    if(status === "fulfilled") {
      toast.success('İşlem Başarılı', {
        action: {
          label: 'Geri Al',
          onClick: () => change(lastChangedId,"pending")
        },
      })
    }

  },[status])



  const columns = [
    {
      header: "Ad Soyad",
      accessorFn: (row) => `${row.user?.first_name} ${row.user?.last_name}`,
    },
    {
      header: "Airdrop ID",
      accessorKey: "airdrop_campaign_id",
    },
    {
      header: "Durum",

      cell:(cell) => cell.row.original.status === "approved" ? (<a href="#" class="btn btn-sm btn-light-success btn-color-success btn-active-light-success px-4 py-2 me-4">Onaylandı</a>) : cell.row.original.status === "rejected" ? <a href="#" class="btn btn-sm btn-light-danger btn-color-danger btn-active-light-danger px-4 py-2 me-4">Reddedildi</a> : <a href="#" class="btn btn-sm btn-light-warning btn-color-warning btn-warning-light-info px-4 py-2 me-4">Beklemede</a>,
    },
    {
      header: "Ödül",
      accessorKey: "reward_amount",
    },
    {
      header: "Girilen Bilgi",
      accessorFn: (row) => `${row.inputs[Object.keys(row.inputs)]}`,
    },

    {
      header: "İşlem",
      cell: (cell) => (
        cell.row.original.status === "pending" ? ( <div className="flex">
        <p
        onClick={() => change(cell.row.original.id, "approved")}
        className="btn btn-sm btn-light-primary btn-flex btn-center btn-active-light-primary me-2"
        data-kt-menu-trigger="click"
        data-kt-menu-placement="bottom-end"
      >

       Onayla
      </p>
      <p
         onClick={() => change(cell.row.original.id, "rejected")}
        className="btn btn-sm btn-light-danger btn-flex btn-center btn-active-light-danger"
        data-kt-menu-trigger="click"
        data-kt-menu-placement="bottom-end"
      >

        Reddet
      </p>
      </div>) :    <p

        className="btn btn-sm btn-light-info btn-flex btn-center btn-active-light-info me-2"
        data-kt-menu-trigger="click"
        data-kt-menu-placement="bottom-end"
      >

       İşlem Yapılmış
      </p>
      ),
    },
  ];

  return (
    <Wrapper title="Airdrop" path="Anasayfa/Airdrop/Katılımcılar">
        {data.length>0 ? <ParticipationsContainer searchQuery={searchQuery} setSearchQuery={setSearchQuery} data={data} columns={columns} totalPages={pages} pageNumber={page} perPage={perPage} total={total} nextPageFn={() => handleNextPage(page)} prevPageFn={()=>handlePrevPage(page)}  /> : <div>Yükleniyor</div>}
    </Wrapper>
  )
}
export default Participations
