import {useSelector} from "react-redux";
import { selectAllAirdrops,selectAirdropById} from ".";


export const useParticipationsStatus = () => useSelector(state=> state.airdropParticipations.participationsStatus);
export const useParticipationsError = () => useSelector(state=> state.airdropParticipations.participationsError);
export const useParticipationsData = () => useSelector(selectAllAirdrops);
export const useParticipationsChangeStatus = () => useSelector(state => state.airdropParticipations.changeStatus);
export const useParticipationsChangeError = () => useSelector(state => state.airdropParticipations.changeError);

export const useParticipationsPage = () => useSelector(state=>state.airdropParticipations.participationPage);
export const useParticipationsPages = () => useSelector(state=>state.airdropParticipations.participationPages);
export const useParticipationsTotal = () => useSelector(state=>state.airdropParticipations.participationTotal);
export const useParticipationsPerPage = () => useSelector(state=>state.airdropParticipations.participationPer_page);
