import { useEffect } from "react"
import Wrapper from "../../../components/Wrapper"
import PromotionParticipationsListContainer from "../../../containers/Promotions/Participations"
import { promocodesclaims } from "../../../stores/promocodesclaims/actions"
import {users} from "../../../stores/users/actions";
import { useData,usePage,usePages,usePerPage,useTotal } from "../../../stores/promocodesclaims/hooks"

import { useSearchParams } from "react-router-dom"
import moment from "moment"
import { Link } from "react-router-dom"


const PromotionParticipationsList = () => {
  let [searchParams, setSearchParams] = useSearchParams();
  const data = useData();
  const page = usePage();
  const pages = usePages();
  const perPage = usePerPage();
  const total = useTotal();

  const newArray = (data && data[0] ? data[0] : []).map(item => {
    return (item?.promocodes || []).map(promo => {
      return {
        title: promo.promo?.title || "",
        claim_reference: promo.code || "",
        id: promo.id || "",
        user_id: promo.user_id || "",
      };
    });
  }).flat();


  const handleNextPage = (page) => {
    setSearchParams({page:page+1})
  }

  const handlePrevPage = (page) => {
    setSearchParams({page:page-1})
  }

  useEffect(() => {
    users.get({page:searchParams.get("page")})
  },[])

  useEffect(()=>{
    promocodesclaims.get({page:searchParams.get("page")})
  },[searchParams])

  const columns = [
    {
      header: "ID",
      accessorKey: "id",
    },
    {
      header: "Katılım Tarihi ",
      accessorFn: (row) => moment(row.created_at).format("LLL")
    },
    {
      header:"Kullanıcı ID",
      cell: (cell) => (
          <Link
                to={`/users/${cell.row.original.user_id}`}
              className="btn btn-sm btn-light btn-flex btn-center btn-active-light-primary"
              data-kt-menu-trigger="click"
              data-kt-menu-placement="bottom-end"
          >

            {cell.row.original.user_id}
          </Link>
      )
    },
    {
      header: "Promosyon Kodu",
      accessorKey: "claim_reference"
    },
    {
      header: "Promosyon Adı",
      accessorKey: "title"
    }


  ];

  return (
      <Wrapper title="Katılımcılar" path="Anasayfa/Promosyonlar/Katılımcılar">
        <PromotionParticipationsListContainer searchable={false} data={newArray} columns={columns} totalPages={pages} pageNumber={page}
                                              perPage={perPage} total={total} nextPageFn={() => handleNextPage(page)}
                                              prevPageFn={() => handlePrevPage(page)}/>
      </Wrapper>
  )
}
export default PromotionParticipationsList
