import { createSlice,createAsyncThunk,createEntityAdapter } from "@reduxjs/toolkit";

import transactionsService from "./service";

const transactionsAdapter = createEntityAdapter();

export const _get = createAsyncThunk("transactions/get", async(params, {rejectWithValue}) => {
    try {
        const response = await transactionsService.get(params);
        return response
    } catch (error) {
         const message = error.response.data
       
        return rejectWithValue(message);
    }
});




const initialState = transactionsAdapter.getInitialState({
    total:"",
    page:"",
    pages:"",
    per_page:"",
    status: 'idle',
    error: null,
    
});


const transactions = createSlice({
    name:"transactions",
    initialState,
    reducers:{
        _reset: (state) => {
            state.status = 'idle';
            state.error = "";
            state.total = "";
            state.pages= "";
            state.per_page = "";
            state.page = "";
        }
    },
    extraReducers:(builder) => {
        builder
        .addCase(_get.pending, (state) => {
            state.status = 'pending'
        })
        .addCase(_get.fulfilled, (state, action) => {
            state.status = 'fulfilled';
            transactionsAdapter.setAll(state, action.payload.items);
            state.total = action.payload.total;
            state.page = action.payload.page;
            state.pages = action.payload.pages;
            state.per_page = action.payload.per_page;
          
        })
        .addCase(_get.rejected, (state,action) => {
            state.status = "rejected"
            state.error = action.payload.errorcode
        })

    }
})

export const {_reset} = transactions.actions;
export default transactions.reducer

export const {
    selectAll: selectAllTransactions,
   
} = transactionsAdapter.getSelectors(state => state.transactions);
