import { useEffect } from "react";
import Wrapper from "../../../components/Wrapper";
import PromotionListContainer from "../../../containers/Promotions/List";
import { promotions } from "../../../stores/promotions/actions";
import {
  useData,
  usePage,
  usePages,
  useTotal,
  usePerPage,
} from "../../../stores/promotions/hooks";
import {useSearchParams} from 'react-router-dom'

const PromotionList = () => {
  let [searchParams, setSearchParams] = useSearchParams();
  const data = useData();
  const page = usePage();
  const pages = usePages();
  const total = useTotal();
  const perPage = usePerPage();

  const handleNextPage = (page) => {
    setSearchParams({page:page+1})
  }

  const handlePrevPage = (page) => {
    setSearchParams({page:page-1})
  }

  useEffect(() => {
    promotions.get({page:searchParams.get("page")});
  }, [searchParams]);

  const canPrevPage = page > 1;
  const canNextPage = total > perPage * page;

  return (
    <Wrapper title="Promosyonlar" path="Anasayfa/Promosyonlar">
      <PromotionListContainer data={data} />
      <div className="col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end">
        <div className="dataTables_paginate paging_simple_numbers">
          <ul className="pagination">
            <li
              className={`paginate_button page-item previous ${
                !canPrevPage && "disabled"
              }`}
            >
              <button
                  onClick={() => handlePrevPage(page)}
                disabled={!canPrevPage}
                aria-controls="kt_customers_table"
                tabindex="0"
                className="page-link"
              >
                <i className="previous"></i>
              </button>
            </li>

            <li
              style={{ display: "flex", alignItems: "center" }}
              className="paginate_button page-item active"
            >
              <p className="text-gray-800 text-center m-0">{`${page} / ${pages}`}</p>
            </li>

            <li
              className={`paginate_button page-item previous ${
                !canNextPage && "disabled"
              }`}
            >
              <button
                disabled={!canNextPage}
                onClick={() => handleNextPage(page)}
                aria-controls="kt_customers_table"
                tabindex="0"
                className="page-link"
              >
                <i className="next"></i>
              </button>
            </li>
          </ul>
        </div>
      </div>
    </Wrapper>
  );
};
export default PromotionList;
