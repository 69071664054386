import { useEffect } from "react";
import { Outlet } from "react-router-dom"
import { useIsLogin } from "../../stores/login/hooks";
import { useNavigate } from "react-router-dom";
const AuthLayout = () => {
  const isLogin = useIsLogin();
  const navigate = useNavigate();

  useEffect(() => {
    if (isLogin) {
      navigate("/")
    }
  },[isLogin])



  return (
    <Outlet/>
  )
}
export default AuthLayout