import instance from '../../utils/api/instance'

const participations = async(params) => {
    const response = await instance.get("stakes",{params:params})
    if(response.status === 200){
        return response.data;
    }

    return Promise.reject(response.data);
}

const cancel = async(data) => {
    const response = await instance.patch("stakes",data)
    if(response.status === 200){
        return response.data;
    }

    return Promise.reject(response.data);
}

const get = async(params) => {
    const response = await instance.get("stake/options",{params:params})
    if(response.status === 200){
        return response.data;
    }

    return Promise.reject(response.data);
}

const edit = async(data) => {
    const response = await instance.patch("stake/options",data)
    if(response.status === 200){
        return response.data;
    }

    return Promise.reject(response.data);
}

const create = async(data) => {
    const response = await instance.post("stake/options",data)
    if(response.status === 201){
        return response.data;
    }

    return Promise.reject(response.data);
}


const stakesService = {
    get,
    edit,
    create,
    participations,
    cancel
};

export default stakesService;