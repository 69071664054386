import { useEffect } from "react";
import Wrapper from "../../components/Wrapper";
import moment from "moment";
import StakePartipicationsContainer from "../../containers/Stakes";
import { toast } from "sonner";
import { useSearchParams } from "react-router-dom"
import {modal} from "../../stores/modal/actions";
import { stakes } from "../../stores/stakes/actions";
import {
  useParticipationsData,
  useCancelStatus,
  useParticipationsPage,
  useParticipationsPerPage,
  useParticipationsPages,
  useParticipationsTotal
} from "../../stores/stakes/hooks";

const StakeParticipations = () => {
  let [searchParams, setSearchParams] = useSearchParams();

  const data = useParticipationsData();
  const cancelStatus = useCancelStatus();
  const page = useParticipationsPage();
  const perPage = useParticipationsPerPage();
  const pages = useParticipationsPages();
  const total = useParticipationsTotal();

  const handleNextPage = (page) => {
    setSearchParams({page:page+1})
  }

  const handlePrevPage = (page) => {
    setSearchParams({page:page-1})
  }

  useEffect(() => {
    stakes.participations({page:searchParams.get("page")});
  }, [searchParams]);

  useEffect(() => {
    const messages = {
      pending: { type: 'loading', text: "Güncelleniyor..." },
      fulfilled: { type: 'success', text: "Güncellendi!" },
      rejected: { type: 'error', text: "Bir hata oluştu!" }
    };

    const message = messages[cancelStatus];
    if (message) {
      toast[message.type](message.text);
    }
  }, [cancelStatus]);

  const cancelStake = (id) => {
    stakes.cancel({
      id:id,
      status:"cancelled"
    })
  }

  const columns = [
    {
      header: "Ad Soyad",
      accessorFn: (row) => `${row?.user?.first_name} ${row?.user?.last_name}`,
    },
    {
      header: "Kilitli Miktar",
      accessorKey: "staked_amount",
    },
    {
      header: "Ödül",
      accessorKey: "release_amount",
    },
    {
      header: "Kilit Açılma Tarihi",
      accessorFn: (row) => `${moment(row?.release_date).format("LLL")}`,
    },

    {
      header: "Stake Durumu",
      cell:(cell) => cell.row.original?.status === "pending_release" ? (<span  className="btn btn-sm btn-light-warning btn-color-warning btn-active-light-warning px-4 py-2 me-4">Bekliyor</span>) : cell.row.original?.status === "cancelled" ? <span  className="btn btn-sm btn-light-danger btn-color-danger btn-active-light-danger px-4 py-2 me-4">İptal Edilmiş</span> :  <span  className="btn btn-sm btn-light-success btn-color-success btn-active-light-success px-4 py-2 me-4">Tamamlanmış</span>,
    },
    {
      header: "İptal",
      cell:(cell) => cell.row.original?.status === "released" ? (<span  className="btn btn-sm btn-light-warning btn-color-warning btn-active-light-warning px-4 py-2 me-4">İptal Edilemez</span>) : <span onClick={()=>cancelStake(cell.row.original?.id)} className="btn btn-sm btn-light-danger btn-color-danger btn-active-light-danger px-4 py-2 me-4">İptal Et</span>,
    },
    {
      header: "Detay",
      cell: (cell) => (
        <div
          onClick={() => modal.append("modal.detail-stake", {...cell.row.original})}
          className="btn btn-sm btn-light btn-flex btn-center btn-active-light-primary"
          data-kt-menu-trigger="click"
          data-kt-menu-placement="bottom-end"
        >

          <i className="ki-duotone ki-eye fs-3 ms-1">
            <span className="path1"></span>
            <span className="path2"></span>
            <span className="path3"></span>
          </i>
        </div>
      ),
    },


  ];
  return (
    <Wrapper title="Stake Listesi" path="Stake/Katılımcılar">
      <StakePartipicationsContainer searchable={false} data={data} columns={columns} totalPages={pages} pageNumber={page} perPage={perPage} total={total} nextPageFn={() => handleNextPage(page)} prevPageFn={()=>handlePrevPage(page)}  />
    </Wrapper>
  );
};
export default StakeParticipations;
