import {useSelector} from "react-redux";
import { selectAllStakes,selectStakesById} from ".";

export const useOptionsStatus = () => useSelector(state=> state.stakes.listStatus);
export const useOptionsError = () => useSelector(state=> state.stakes.listError);
export const useCreateOptionStatus = () => useSelector(state=> state.stakes.createOptionStatus);
export const useCreateOptionError = () => useSelector(state=> state.stakes.createOptionError);
export const useEditOptionStatus = () => useSelector(state=> state.stakes.editOptionStatus);
export const useEditOptionError = () => useSelector(state=> state.stakes.editOptionError);
export const useOptionsData = () => useSelector(selectAllStakes);
export const useOptionById = (id) => useSelector(state => selectStakesById(state,id))

export const useParticipationsStatus = () => useSelector(state=> state.stakes.participationsStatus);
export const useParticipationsError = () => useSelector(state=> state.stakes.participationsError);
export const useParticipationsData = () => useSelector(selectAllStakes);

export const useCancelStatus = () => useSelector(state => state.stakes.cancelStatus);
export const useCancelError = () => useSelector(state=> state.stakes.cancelError);

export const useListPage = () => useSelector(state=>state.stakes.listPage);
export const useListPages = () => useSelector(state=>state.stakes.listPages);
export const useListTotal = () => useSelector(state=>state.stakes.listTotal);
export const useListPerPage = () => useSelector(state=>state.stakes.listPer_page);

export const useParticipationsPage = () => useSelector(state=>state.stakes.participationsPage);
export const useParticipationsPages = () => useSelector(state=>state.stakes.participationsPages);
export const useParticipationsTotal = () => useSelector(state=>state.stakes.participationsTotal);
export const useParticipationsPerPage = () => useSelector(state=>state.stakes.participationsPer_page);