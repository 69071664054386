import { Form, Formik, Field, ErrorMessage } from "formik";
import Input from "../../../components/Input";
import { useState, useEffect } from "react";
import { createPromoScheme } from "../../../validations";
import { toast } from "sonner";
import { promotions } from "../../../stores/promotions/actions";
import {
  useCreateStatus,
  useCreateError,
} from "../../../stores/promotions/hooks";
import { useNavigate } from "react-router-dom";
const PromotionCreateContainer = ({ data }) => {
  const navigate = useNavigate();
  const [needStake, setNeedStake] = useState(false);
  const [needBalance, setNeedBalance] = useState(false);
  const createStatus = useCreateStatus();
  const createError = useCreateError();
  const formatDate = (isoString) => {
    return isoString ? new Date(isoString).toISOString().slice(0, 16) : "";
  };

  useEffect(() => {
    if (createStatus === "fulfilled") {
      toast.success("Promosyon Oluşturuldu!", { duration: 1500 });
      setTimeout(() =>{
        navigate("/promotions/list")
      } ,1500)
    } else if (createStatus === "rejected") {
      toast.error(createError);
    }
    return () => {
      promotions.reset();
    };
  }, [createStatus]);

  return (
    <div className="card">
      <div className="card-body">
        <Formik
          validationSchema={createPromoScheme}
          initialValues={{
            title: "",
            brand_image: "",
            brand_name: "",
            cover_image: "",
            description: "",
            start_date: "",
            end_date: "",
            require_stake: "",
            require_balance: "",
            claim_count: "",
            merchant_id: "",
          }}
          onSubmit={(values) => {
            promotions.create({
              title: values.title,
              brand_image: values.brand_image,
              brand_name: values.merchant_id,
              cover_image: values.cover_image,
              description: values.description,
              start_date: formatDate(values.start_date),
              end_date: formatDate(values.end_date),
              require_stake: needStake === false ? null : values.require_stake,
              require_balance:
                needBalance === false ? null : values.require_balance,
              claim_count: Number(values.claim_count),
              merchant_id: Number(values.merchant_id),
              enabled: true,
              method: "generate",
            });
          }}
        >
          {({ values }) => (
            <Form
              className="mx-auto mw-600px w-100 pt-15 pb-10"
              novalidate="novalidate"
              id="create_promo_form"
            >
              <div>
                <div className="w-100">
                  <div className="pb-10 pb-lg-12">
                    <h2 className="fw-bold text-dark">Promosyon Detayları</h2>

                    <div className="text-muted fw-semibold fs-6">
                      Girdiğiniz değerleri kontrol etmeyi unutmayınız.
                    </div>
                  </div>

                  <div className="fv-row mb-10">
                    <label className="form-label required">
                      Promosyon Başlığı
                    </label>

                    <Input name="title" placeholder="Promosyon Başlığı" />
                  </div>

                  <div className="fv-row mb-4">
                    <label className="fs-6 fw-semibold form-label required">
                      Promosyon Görseli
                    </label>
                    <div className="row">
                      <div className="col-md-6">
                        <Input
                          name="cover_image"
                          placeholder="Promosyon Görsel Link"
                        />
                      </div>
                      <div className="col-md-6">
                        <div
                          className="input-group "
                          id="promo_image_group"
                          data-td-target-input="nearest"
                          data-td-target-toggle="nearest"
                        >
                          <div
                            className="image-input image-input-empty image-input-outline"
                            data-kt-image-input="true"
                            style={{
                              backgroundImage: `url(${values.cover_image})`,
                            }}
                          >
                            <div className="image-input-wrapper w-300px h-150px"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="fv-row mb-10">
                    <label className="form-label required">İş Ortağı</label>

                    <Field
                      as="select"
                      name="merchant_id"
                      className="form-select form-select-solid"
                    >
                      <option value="">Seçiniz</option>
                      {data.map((partner, index) => (
                        <option value={partner.id}>{partner.name}</option>
                      ))}
                    </Field>
                    <ErrorMessage
                      component="small"
                      name="merchant_id"
                      className="badge badge-light-danger mt-2"
                    />
                  </div>

                  <div className="fv-row mb-4">
                    <label className="fs-6 fw-semibold form-label required">
                      İş Ortağı Logo
                    </label>
                    <div className="row">
                      <div className="col-md-6">
                        <Input
                          name="brand_image"
                          placeholder="İş Ortağı Logo Link"
                        />
                      </div>
                      <div className="col-md-6">
                        <div
                          className="input-group "
                          id="promo_image_group"
                          data-td-target-input="nearest"
                          data-td-target-toggle="nearest"
                        >
                          <div
                            className="image-input image-input-empty image-input-outline"
                            data-kt-image-input="true"
                            style={{
                              backgroundImage: `url(${values.brand_image})`,
                            }}
                          >
                            <div className="image-input-wrapper w-150px h-150px"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="fv-row mb-10">
                    <label className="form-label required">
                      Promo Açıklaması
                    </label>

                    <Field
                      as="textarea"
                      name="description"
                      className="form-control form-control-solid"
                      row="3"
                    ></Field>
                    <ErrorMessage
                      component="small"
                      name="description"
                      className="badge badge-light-danger mt-2"
                    />
                  </div>

                  <div className="fv-row mb-10">
                    <label className="form-label required">
                      Promo Kod Sayısı
                    </label>

                    <Input
                      type="number"
                      name="claim_count"
                      placeholder="Kod Sayısı"
                    />
                  </div>

                  <div className="fv-row mb-10 mt-5">
                    <label className="fs-6 fw-semibold form-label required">
                      Stake
                    </label>

                    <div
                      className="input-group"
                      id="stake_group"
                      data-td-target-input="nearest"
                      data-td-target-toggle="nearest"
                    >
                      <div className="form-check form-switch form-check-custom form-check-solid me-10">
                        <label
                          className="form-check-label m-2"
                          for="stakeSwitch"
                        >
                          Gereksiz
                        </label>
                        <input
                          className="form-check-input h-30px w-60px"
                          type="checkbox"
                          onChange={(e) => setNeedStake(e.target.checked)}
                          checked={needStake}
                          id="stakeSwitch"
                        />
                        <label className="form-check-label" for="stakeSwitch">
                          Gerekli
                        </label>
                      </div>
                      {needStake && (
                        <Input
                          placeholder="Stake Miktarı"
                          name="require_stake"
                        />
                      )}
                    </div>
                  </div>

                  <div className="fv-row mb-10 mt-5">
                    <label className="fs-6 fw-semibold form-label required">
                      Cüzdanda Bulundurma
                    </label>

                    <div
                      className="input-group"
                      id="cuzdan_group"
                      data-td-target-input="nearest"
                      data-td-target-toggle="nearest"
                    >
                      <div className="form-check form-switch form-check-custom form-check-solid me-10">
                        <label
                          className="form-check-label m-2"
                          for="cuzdanSwitch"
                        >
                          Gereksiz
                        </label>
                        <input
                          className="form-check-input h-30px w-60px"
                          type="checkbox"
                          onChange={(e) => setNeedBalance(e.target.checked)}
                          checked={needBalance}
                          id="cuzdanSwitch"
                        />
                        <label className="form-check-label" for="cuzdanSwitch">
                          Gerekli
                        </label>
                      </div>
                      {needBalance && (
                        <Input
                          placeholder="Coin miktarı"
                          name="require_balance"
                        />
                      )}
                    </div>
                  </div>

                  <div className="fv-row mb-0">
                    <label className="fs-6 fw-semibold form-label required">
                      Başlangıç Tarihi
                    </label>

                    <Input type="datetime-local" name="start_date" />
                  </div>

                  <div className="fv-row mb-0">
                    <label className="fs-6 fw-semibold form-label ">
                      Bitiş Tarihi
                    </label>

                    <Input type="datetime-local" name="end_date" />
                  </div>
                </div>
              </div>

              <div className="d-flex flex-center pt-15">
                <div>
                  <button
                    type="submit"
                    disabled={createStatus === "pending"}
                    id="kt_modal_add_customer_submit"
                    className="btn btn-primary"
                  >
                    {createStatus === "pending" ? (
                      <span
                        style={{ display: "block" }}
                        className="indicator-progress"
                      >
                        Lütfen Bekleyin...
                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                      </span>
                    ) : (
                      <span className="indicator-label">Ekle</span>
                    )}
                  </button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};
export default PromotionCreateContainer;
