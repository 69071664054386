import { useEffect } from "react";
import moment from "moment";
import { Link } from "react-router-dom";
const DetailTransaction = ({ destroy,modalData }) => {
 

  const escFunction = (e) => {
    if (e.key === "Escape") {
      destroy();
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", escFunction, false);

    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, [escFunction]);

  const transactionType = (type) => {
    switch (type) {
        case "airdrop":
            return "Airdrop"
        case "stake":
            return "Stake"
        case "withdraw":
            return "Çekim"
        case "deposit":
            return "Yatırım"
        case "stake_release":
            return "Stake Kazancı"
        default:
            return "Bilinmeyen"
    }
}

const transactionStatus = (type) => {
    switch (type) {
        case "created":
            return (<span class="btn btn-sm btn-light-warning btn-color-warning btn-active-light-warning px-4 py-2 me-4">Oluşturuldu</span>)
        case "success":
            return (<span class="btn btn-sm btn-light-success btn-color-success btn-active-light-success px-4 py-2 me-4">Başarılı</span>)
        case "failed":
            return (<span class="btn btn-sm btn-light-danger btn-color-danger btn-active-light-danger px-4 py-2 me-4">Başarısız</span>)
        default:
            return "Bilinmeyen"
    }
}

  return (
    <div className="form" id="kt_modal_add_customer_form">
      <div className="modal-header" id="kt_modal_add_customer_header">
        <h2 className="fw-bold">Detay</h2>

        <div
          onClick={destroy}
          id="kt_modal_add_customer_close"
          className="btn btn-icon btn-sm btn-active-icon-primary"
        >
          <i className="ki-duotone ki-cross fs-1">
            <span className="path1"></span>
            <span className="path2"></span>
          </i>
        </div>
      </div>

      <div>
          <div className="modal-body py-10 px-lg-17">
           
          <div className="row">
          <div
              className="scroll-y  col-md-12"
              id="kt_modal_add_customer_scroll"
             
            >
               <div className="fv-row mb-7">
              <div className="fw-bold mt-5">Gönderici Adres</div>

              <div className="text-gray-600">{modalData.from_address} </div>
              </div>
              <div className="fv-row mb-7">
              <div className="fw-bold mt-5">Alıcı Adres</div>

              <div className="text-gray-600">{modalData.to_address}</div>
              </div>
              <div className="fv-row mb-7">
              <div className="fw-bold mt-5">Miktar</div>

              <div className="text-gray-600">{modalData.amount} IZMR</div>
              </div>
              <div className="fv-row mb-7">
              <div className="fw-bold mt-5">Durum</div>

              {transactionStatus(modalData.status)}
              </div>
              <div className="fv-row mb-7">
              <div className="fw-bold mt-5">Tip </div>

              {transactionType(modalData.type)}
              </div>
              <div className="fv-row mb-7">
              <div className="fw-bold mt-5">TXID</div>

              <div className="text-gray-600">{modalData.txid} </div>
              </div>
              <div className="fv-row mb-7">
              <div className="fw-bold mt-5">Gönderim Zamanı</div>

              <div className="text-gray-600">{moment(modalData.created_at).format("LLL")}</div>
              </div>
              <div className="fv-row mb-7">
              <div className="fw-bold mt-5">Gönderim Ulaşma Zamanı</div>

              <div className="text-gray-600">{moment(modalData.updated_at).format("LLL")}</div>
              </div>
            </div>
            <div
              className="scroll-y col-md-12"
              id="kt_modal_add_customer_scroll"
             
            >
               <div className="fv-row mb-7">
              <div className="fw-bold mt-5">Gönderen Ad Soyad</div>

              <div className="text-gray-600">{modalData.user.first_name } {modalData.user.last_name}</div>
              </div>
              <div className="fv-row mb-7">
              <div className="fw-bold mt-5">Gönderen Telefon Numarası</div>

              <div className="text-gray-600">{modalData.user.phone_number}</div>
              </div>
              <div className="fv-row mb-7">
              <div className="fw-bold mt-5">Gönderen Bakiyesi</div>

              <div className="text-gray-600">{modalData.user.balance} IZMR</div>
              </div>
              <div className="fv-row mb-7">
              <Link to={`/users/${modalData.user_id}`} onClick={destroy} className="btn btn-sm btn-light btn-color-info btn-active-light-info px-4 py-2 me-4">Kullanıcıyı Detaylı İncele</Link>

            
              </div>
           
             
            </div>
          </div>

          </div>

         
        </div>
    </div>
  );
};
export default DetailTransaction;
