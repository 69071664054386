import store from "..";
import {_reset,_get, _create,_edit, _participations,_cancel}  from ".";

export const stakes = {
    reset:() => {
        store.dispatch(_reset())
    },
    options: (params) => {
        store.dispatch(_get(params))
    },
    create: (data) => {
        store.dispatch(_create(data))
    },
    edit: (data) => {
        store.dispatch(_edit(data))
    },
    participations:(params) => {
        store.dispatch(_participations(params))
    },
    cancel:(data) => {
        store.dispatch(_cancel(data))
    }
}