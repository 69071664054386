import { useEffect,useState } from "react"
import Wrapper from "../../components/Wrapper"
import UsersContainer from "../../containers/Users"
import {users} from "../../stores/users/actions"
import {useData,usePage,usePages,useTotal,usePerPage} from "../../stores/users/hooks"
import { Link,useSearchParams } from "react-router-dom"
import {useDebounce} from "use-debounce";
import Decimal from "decimal.js"

const Users = () => {
  let [searchParams, setSearchParams] = useSearchParams();
  const [searchQuery, setSearchQuery] = useState("");
    const [debouncedSearchQuery] = useDebounce(searchQuery, 500);


  const data = useData();
  const page = usePage();
  const pages = usePages();
  const total = useTotal();
  const perPage = usePerPage();

  const handleNextPage = (page) => {
    setSearchParams({page:page+1})
  }

  const handlePrevPage = (page) => {
    setSearchParams({page:page-1})
  }

  useEffect(()=>{
    users.get({page:searchParams.get("page")})
  },[searchParams])

  useEffect(() => {
    users.get({search:debouncedSearchQuery})
  }, [debouncedSearchQuery]);


  const columns = [
    {
      header: "Ad Soyad",
      accessorFn: (row) => `${row?.first_name} ${row?.last_name}`,
    },
    {
      header: "Email",
      accessorKey: "email",
    },
    {
      header: "Telefon",
      accessorKey: "phone_number",
    },
    {
      header: "Cüzdan Adr",
      accessorKey: "wallet_address",
    },
    {
      header: "Bakiye",
      accessorFn: (row) => `${Decimal(row?.balance).toNumber()} IZMR`,
    },
    {
      header: "Staked",
      accessorFn: (row) => `${Decimal(row?.staked_balance).toNumber()} IZMR`,
    },
    {
      header: "Airdrop",
      accessorFn: (row) => `${Decimal(row?.airdrop_balance).toNumber()} IZMR`,
    },
    {
      header: "Detay",
      cell: (cell) => (
        <Link
          to={`/users/${cell.row.original.id}`}
          className="btn btn-sm btn-light btn-flex btn-center btn-active-light-primary"
          data-kt-menu-trigger="click"
          data-kt-menu-placement="bottom-end"
        >

          <i className="ki-duotone ki-eye fs-3 ms-1">
            <span className="path1"></span>
            <span className="path2"></span>
            <span className="path3"></span>
          </i>
        </Link>
      ),
    },
  ];

  return (

    <Wrapper title="Kullanıcılar" path="Anasayfa/Kullanıcılar">
        <UsersContainer searchQuery={searchQuery} setSearchQuery={setSearchQuery} data={data} columns={columns}  totalPages={pages} pageNumber={page} perPage={perPage} total={total} nextPageFn={() => handleNextPage(page)} prevPageFn={()=>handlePrevPage(page)}  />
    </Wrapper>
  )
}
export default Users
