import { createSlice,createAsyncThunk,createEntityAdapter } from "@reduxjs/toolkit";

import promocodesclaimsService from "./service";

const promocodesclaimsAdapter = createEntityAdapter();

export const _get = createAsyncThunk("promocodes/claims/get", async(params, {rejectWithValue}) => {
    try {
        const response = await promocodesclaimsService.get(params);

        return response;
    } catch (error) {
         const message = error.response.data

        return rejectWithValue(message);
    }
});



const initialState = promocodesclaimsAdapter.getInitialState({
    total: 0,
    page: 1,
    pages: 1,
    per_page: 10,
    status: 'idle',
    error: null,

});


const promocodesclaims = createSlice({
    name:"promocodesclaims",
    initialState,
    reducers:{
        _reset: (state) => {
            state.status = 'idle';
            state.error = "";
            state.editStatus = "idle";
            state.editError = "";
            state.createStatus = "idle";
            state.createError = ""
        }
    },
    extraReducers:(builder) => {
        builder
        .addCase(_get.pending, (state) => {
            state.status = 'pending'
        })
        .addCase(_get.fulfilled, (state, action) => {
            state.status = 'fulfilled';
            promocodesclaimsAdapter.setAll(state, action.payload);
            state.total = action.payload.total;
            state.page = action.payload.page;
            state.pages = action.payload.pages;
            state.per_page = action.payload.per_page;
        })
        .addCase(_get.rejected, (state,action) => {
            state.status = "rejected"
            state.error = action.payload
        })


    }
})

export const {_reset} = promocodesclaims.actions;
export default promocodesclaims.reducer

export const {
    selectAll: selectAllPromocodesClaims,
    selectById: selectPromocodesClaimsById,


} = promocodesclaimsAdapter.getSelectors(state => state.promocodesclaims);
