

export const apperance = [
  {
    key: 'default',
    value: 'Sistem'
  },
  {
    key: 'light',
    value: 'Açık'
  },
  {
    key: 'dark',
    value: 'Karanlık'
  }
]

export const getApperanceName = key => apperance.find(a => a.key === key)?.value
export const getApperanceElem = (key, colorScheme = false) => {
  switch (key) {
    case 'default':
      if (!colorScheme) {
        return (<div className="menu-link px-3 py-2">
        <span className="menu-icon">
          <i className="ki-duotone ki-screen fs-2">
            <span className="path1"></span>
            <span className="path2"></span>
            <span className="path3"></span>
            <span className="path4"></span>
          </i>
        </span>
        <span className="menu-title">System</span>
      </div>)
      } else {
        if (colorScheme === 'light') {
          return ( <div className="menu-link px-3 py-2">
          <span className="menu-icon">
            <i className="ki-duotone ki-night-day fs-2">
              <span className="path1"></span>
              <span className="path2"></span>
              <span className="path3"></span>
              <span className="path4"></span>
              <span className="path5"></span>
              <span className="path6"></span>
              <span className="path7"></span>
              <span className="path8"></span>
              <span className="path9"></span>
              <span className="path10"></span>
            </i>
          </span>
          <span className="menu-title">Light</span>
        </div>)
        } else {
          return ( <div className="menu-link px-3 py-2">
          <span className="menu-icon">
            <i className="ki-duotone ki-moon fs-2">
              <span className="path1"></span>
              <span className="path2"></span>
            </i>
          </span>
          <span className="menu-title">Dark</span>
        </div>)
        }
      }
    case 'light':
      return  ( <div className="menu-link px-3 py-2">
      <span className="menu-icon">
        <i className="ki-duotone ki-night-day fs-2">
          <span className="path1"></span>
          <span className="path2"></span>
          <span className="path3"></span>
          <span className="path4"></span>
          <span className="path5"></span>
          <span className="path6"></span>
          <span className="path7"></span>
          <span className="path8"></span>
          <span className="path9"></span>
          <span className="path10"></span>
        </i>
      </span>
      <span className="menu-title">Light</span>
    </div>)
    case 'dark':
      return ( <div className="menu-link px-3 py-2">
      <span className="menu-icon">
        <i className="ki-duotone ki-moon fs-2">
          <span className="path1"></span>
          <span className="path2"></span>
        </i>
      </span>
      <span className="menu-title">Dark</span>
    </div>)
  }
}

