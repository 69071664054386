import { createSlice,createAsyncThunk,createEntityAdapter } from "@reduxjs/toolkit";

import airdropsService from "./service";

const airdropAdapter = createEntityAdapter();



export const _participations = createAsyncThunk("airdrop/participations", async(params, {rejectWithValue}) => {
    try {
        const response = await airdropsService.participations(params);
        return response
    } catch (error) {
         const message = error.response.data
       
        return rejectWithValue(message);
    }
});



export const _changeStatus = createAsyncThunk("airdrop/change", async(data, {rejectWithValue}) => {
    try {
        const response = await airdropsService.editParticipations(data);
       
        return response

    } catch (error) {
         const message = error.response.data
     
        return rejectWithValue(message);
    }
});


const initialState = airdropAdapter.getInitialState({
  
    participationTotal: 0,
    participationPage: 1,
    participationPages: 1,
    participationPer_page: 10,
   
    participationsStatus:'idle',
    participationsError:null,
    changeStatus:"idle",
    changeError:null
});


const airdropParticipations = createSlice({
    name:"airdropParticipations",
    initialState,
    reducers:{
        _reset: (state) => {

            state.participationsStatus = 'idle';
            state.participationsError = "error";
            state.changeStatus = "idle";
            state.changeError = ""
        }
    },
    extraReducers:(builder) => {
        builder
   
        .addCase(_participations.pending, (state) => {
            state.participationsStatus = 'pending'
        })
        .addCase(_participations.fulfilled, (state,action) => {
            state.participationsStatus = "fulfilled";
            state.participationTotal = action.payload.total;
            state.participationPage = action.payload.page;
            state.participationPages = action.payload.pages;
            state.participationPer_page = action.payload.per_page;
            airdropAdapter.setAll(state, action.payload.items);
        })
        .addCase(_participations.rejected,(state,action)=>{
            state.participationsStatus = "rejected"
            state.participationsError = action.payload
        })
        .addCase(_changeStatus.pending, (state) => {
            state.changeStatus = 'pending'
        })
        .addCase(_changeStatus.fulfilled, (state,action) => {
            state.changeStatus = "fulfilled";
            airdropAdapter.upsertOne(state, action.payload);
        })
        .addCase(_changeStatus.rejected,(state,action)=>{
            state.changeStatus = "rejected"
            state.changeError = action.payload
        })
    }
})

export const {_reset} = airdropParticipations.actions;
export default airdropParticipations.reducer

export const {
    selectAll: selectAllAirdrops,
 
    selectById: selectAirdropById,
    selectIds: selectUserIds,
   
} = airdropAdapter.getSelectors(state => state.airdropParticipations);
