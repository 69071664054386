import store from "..";
import {_reset,_get,_edit}  from ".";

export const users = {
    reset:() => {
        store.dispatch(_reset())
    },
    get: (params) => {
        store.dispatch(_get(params))
    },
    edit: (data) => {
        store.dispatch(_edit(data))
    }
}