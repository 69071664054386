import instance from '../../utils/api/instance'

const get = async(params) => {
    const response = await instance.get("transactions",{
        params:params
    })
    if(response.status === 200){
        return response.data;
    }

    return Promise.reject(response.data);
}



const transactionsService = {
    get
};

export default transactionsService;