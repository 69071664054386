import { modal } from "../../../stores/modal/actions";
import { users } from "../../../stores/users/actions";
import { useEditStatus,useEditError } from "../../../stores/users/hooks";
import { toast } from "sonner";
import { useState, useEffect } from "react";
import Decimal from "decimal.js";
const UserDetailContainer = ({ data }) => {
  const [banned, setBanned] = useState(data?.banned);
  const [verifiedPhone,setVerifiedPhone] = useState(data?.phone_verified);
  const [verifiedEmail,setVerifiedEmail] = useState(data?.email_verified);
  const editStatus = useEditStatus();
  const editError = useEditError();

  useEffect(()=>{
    setVerifiedEmail(data?.email_verified);
    setVerifiedPhone(data?.phone_verified);
    setBanned(data?.banned);

  },[data])

  const editSecurity = () => {
    users.edit({
      id:data.id,
      banned:banned,
      phone_verified:verifiedPhone,
      email_verified:verifiedEmail
    })
  }

  useEffect(()=>{
    if(editStatus === "fulfilled"){
      toast.success("Değişiklikler Kaydedildi")
    }else if(editStatus === "rejected"){
      toast.error(editError)
    };
    return () => {
      users.reset();
    }
  },[editStatus])


  return (
    <div className="d-flex flex-column flex-xl-row">
      <div className="flex-column flex-lg-row-auto w-100 w-xl-350px mb-10">
        <div className="card mb-5 mb-xl-8">
          <div className="card-body pt-15">
            <div className="d-flex flex-center flex-column mb-5">
              <a
                href="#"
                className="fs-3 text-gray-800 text-hover-primary fw-bold mb-1"
              >
                {data?.first_name} {data?.last_name}{" "}
                {data?.banned && (
                  <span className="badge badge-light-danger mt-2">Yasaklı</span>
                )}
              </a>

              <div className="fs-5 fw-semibold text-muted mb-6">
                {data?.email}
              </div>

              <div className="d-flex flex-wrap flex-center">
                <div className="border border-gray-300 border-dashed rounded py-3 px-3 mx-4 mb-3">
                  <div className="fs-4 fw-bold text-gray-700">
                    <span className="w-50px">{data?.airdrop_balance}</span>
                  </div>
                  <div className="fw-semibold text-muted">Airdrop IZMR</div>
                </div>

                <div className="border border-gray-300 border-dashed rounded py-3 px-3 mb-3">
                  <div className="fs-4 fw-bold text-gray-700">
                    <span className="w-50px">{data?.staked_balance}</span>
                  </div>
                  <div className="fw-semibold text-muted">Staked IZMR</div>
                </div>
              </div>
            </div>

            <div className="d-flex flex-stack fs-4 py-3">
              <div
                className="fw-bold rotate collapsible"
                data-bs-toggle="collapse"
                href="#kt_customer_view_details"
                role="button"
                aria-expanded="false"
                aria-controls="kt_customer_view_details"
              >
                Detay
              </div>
              <span
                data-bs-toggle="tooltip"
                data-bs-trigger="hover"
                title="Edit customer details"
              >
                <span
                  onClick={() => modal.append("modal.update-user", { ...data })}
                  className="btn btn-sm btn-light-primary"
                  data-bs-toggle="modal"
                  data-bs-target="#kt_modal_update_customer"
                >
                  Düzenle
                </span>
              </span>
            </div>

            <div className="separator separator-dashed my-3"></div>

            <div id="kt_customer_view_details" className="collapse show">
              <div className="py-5 fs-6">



                <div className="fw-bold mt-5">Kullanıcı ID</div>
                <div className="text-gray-600">{data?.id}</div>
                <div className="fw-bold mt-5">Cüzdan Adresi</div>
                <div className="text-gray-600">{data?.wallet_address}</div>
                <div className="fw-bold mt-5">E-Posta</div>
                <div className="text-gray-600">
                  <a href="#" className="text-gray-600 text-hover-primary">
                    {data?.email}
                  </a>
                </div>
                <div className="fw-bold mt-5">Telefon Numarası</div>
                <div className="text-gray-600">
                  <a href="#" className="text-gray-600 text-hover-primary">
                    {data?.phone_number}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="card mb-5 mb-xl-8">
          <div className="card-header border-0">
            <div className="card-title">
              <h3 className="fw-bold m-0">Güvenlik</h3>
            </div>
          </div>

          <div className="card-body pt-2">
            <div className="py-2">
              <div className="d-flex flex-stack">
                <div className="d-flex">
                  <div className="d-flex flex-column">
                    <a

                      className="fs-5 text-dark  fw-bold"
                    >
                      Telefon Doğrulaması
                    </a>
                  </div>
                </div>
                <div className="d-flex justify-content-end">
                  <label className="form-check form-switch form-switch-sm form-check-custom form-check-solid">
                    <input
                      className="form-check-input"
                      name="phone"
                      type="checkbox"
                      onChange={e => setVerifiedPhone(e.target.checked)}
                      checked={verifiedPhone}
                    />

                    <span
                      className="form-check-label fw-semibold text-muted"
                      for="kt_modal_connected_accounts_google"
                    ></span>
                  </label>
                </div>
              </div>

              <div className="separator separator-dashed my-5"></div>

              <div className="d-flex flex-stack">
                <div className="d-flex">
                  <div className="d-flex flex-column">
                    <a
                      href="#"
                      className="fs-5 text-dark  fw-bold"
                    >
                      E-Posta Doğrulaması
                    </a>
                  </div>
                </div>
                <div className="d-flex justify-content-end">
                  <label className="form-check form-switch form-switch-sm form-check-custom form-check-solid">
                    <input
                      className="form-check-input"
                      name="email"
                      type="checkbox"

                      onChange={e => setVerifiedEmail(e.target.checked)}
                      checked={verifiedEmail}

                    />

                    <span
                      className="form-check-label fw-semibold text-muted"
                      for="kt_modal_connected_accounts_github"
                    ></span>
                  </label>
                </div>
              </div>
              <div className="separator separator-dashed my-5"></div>

              <div className="d-flex flex-stack">
                <div className="d-flex">
                  <div className="d-flex flex-column">
                    <a
                      href="#"
                      className="fs-5 text-dark  fw-bold"
                    >
                      Yasakla
                    </a>
                  </div>
                </div>
                <div className="d-flex justify-content-end">
                  <label className="form-check form-switch form-switch-sm form-check-custom form-check-solid">
                    <input
                      className="form-check-input"
                      name="banned"
                      type="checkbox"
                      onChange={e => setBanned(e.target.checked)}
                      checked={banned}


                    />

                    <span
                      className="form-check-label fw-semibold text-muted"
                      for="kt_modal_connected_accounts_github"
                    ></span>
                  </label>
                </div>
              </div>
            </div>
          </div>

          <div className="card-footer border-0 d-flex justify-content-center pt-0">
            <button onClick={() => editSecurity()} disabled={editStatus === "pending"} className="btn btn-sm btn-light-primary">
                  {
                    editStatus === "pending" ?  <span style={{ display: "block" }} className="indicator-progress">
                    Kaydediliyor...
                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                  </span>: <span>Değişiklikleri Kaydet</span>
                  }
            </button>
          </div>
        </div>
      </div>

      <div className="flex-lg-row-fluid ms-lg-15">
        <div className="card pt-4 mb-6 mb-xl-9 ">
          <div className="card-header border-0">
            <div className="card-title">
              <h2 className="fw-bold">Hesap Bakiyesi</h2>
            </div>

            <div className="card-toolbar">
              <div
                href="#"
                className="btn btn-sm btn-flex btn-light-primary"
                onClick={() =>
                  modal.append("modal.adjust-balance", {
                    balance: data?.balance,
                    id: data?.id,
                  })
                }
              >
                <i className="ki-duotone ki-pencil fs-3">
                  <span className="path1"></span>
                  <span className="path2"></span>
                </i>
                Düzenle
              </div>
            </div>
          </div>

          <div className="card-body pt-0">
            <div className="fw-bold fs-2">
              {Decimal(data?.balance || 0).toNumber()}
              <span className="text-muted fs-4 fw-semibold">IZMR</span>
            </div>
          </div>
        </div>
        <div className="tab-content" id="myTabContent">
          <div
            className="tab-pane fade show active"
            id="kt_customer_view_overview_tab"
            role="tabpanel"
          >
            <div className="card pt-4 mb-6 mb-xl-9">
              <div className="card-header border-0">
                <div className="card-title">
                  <h2>Referanslar</h2>
                </div>
              </div>

              <div className="card-body pt-0 pb-5">
                <table
                  className="table align-middle table-row-dashed gy-5"
                  id="kt_table_customers_payment"
                >
                  <thead className="border-bottom border-gray-200 fs-7 fw-bold">
                    <tr className="text-start text-muted text-uppercase gs-0">
                      <th className="min-w-100px">Ad Soyad</th>
                      <th>Email</th>
                    </tr>
                  </thead>
                  {data?.referrals.length > 0 ? (
                    <tbody className="fs-6 fw-semibold text-gray-600">
                      {data?.referrals.map((ref, index) => (
                        <tr key={index}>
                          <td>
                            <a
                              href="#"
                              className="text-gray-600 text-hover-primary mb-1"
                            >
                              {ref.name_masked}
                            </a>
                          </td>
                          <td>
                            <a
                              href="#"
                              className="text-gray-600 text-hover-primary mb-1"
                            >
                              {ref.email_masked}
                            </a>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  ) : (
                    <tbody>
                      <tr>
                        <td>
                          {" "}
                          <a
                            href="#"
                            className="text-gray-600 text-hover-primary mb-1"
                          >
                            Referans Yok
                          </a>
                        </td>
                      </tr>
                    </tbody>
                  )}
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default UserDetailContainer;
