import store from "..";
import {_reset,_get,_create,_resetKey}  from ".";

export const partners = {
    reset:() => {
        store.dispatch(_reset())
    },
    get: () => {
        store.dispatch(_get())
    },
    create: (data) => {
        store.dispatch(_create(data))
    },
    resetKey: (data) => {
        store.dispatch(_resetKey(data))
    }
}