import { useEffect } from "react";
import moment from "moment";
import { Link } from "react-router-dom";
const DetailStake = ({ destroy,modalData }) => {
 

  const escFunction = (e) => {
    if (e.key === "Escape") {
      destroy();
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", escFunction, false);

    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, [escFunction]);

 

  return (
    <div className="form" id="kt_modal_add_customer_form">
      <div className="modal-header" id="kt_modal_add_customer_header">
        <h2 className="fw-bold">Detay</h2>

        <div
          onClick={destroy}
          id="kt_modal_add_customer_close"
          className="btn btn-icon btn-sm btn-active-icon-primary"
        >
          <i className="ki-duotone ki-cross fs-1">
            <span className="path1"></span>
            <span className="path2"></span>
          </i>
        </div>
      </div>

      <div>
          <div className="modal-body py-10 px-lg-17">
           
          <div className="row">
          <div
              className="scroll-y  col-md-6"
              id="kt_modal_add_customer_scroll"
             
            >
               <div className="fv-row mb-7">
              <div className="fw-bold mt-5">Stake Aktarım ID</div>

              <div className="text-gray-600">{modalData.stake_transaction_id} </div>
              </div>
              <div className="fv-row mb-7">
              <div className="fw-bold mt-5">Ödül</div>

              <div className="text-gray-600">{modalData.release_amount} IZMR</div>
              </div>
              <div className="fv-row mb-7">
              <div className="fw-bold mt-5">Stake Açılma Tarihi</div>

              <div className="text-gray-600">{moment(modalData.release_date).format("LLL")}</div>
              </div>
              <div className="fv-row mb-7">
              <div className="fw-bold mt-5">Stake Etme Tarihi</div>

              <div className="text-gray-600">{moment(modalData.created_at).format("LLL")}</div>
              </div>
              <div className="fv-row mb-7">
              <div className="fw-bold mt-5">Stake Miktarı</div>

              <div className="text-gray-600">{modalData.staked_amount}</div>
              </div>
              <div className="fv-row mb-7">
              <div className="fw-bold mt-5">Stake Durumu</div>

              <div className="text-gray-600">{modalData.status === "pending_release" ? <span  className="btn btn-sm btn-light-warning btn-color-warning btn-active-light-warning px-4 py-2 me-4">Bekliyor</span> : <span  className="btn btn-sm btn-light-success btn-color-success btn-active-light-success px-4 py-2 me-4">Tamamlanmış</span>}</div>
              </div>
            </div>
            <div
              className="scroll-y col-md-6"
              id="kt_modal_add_customer_scroll"
             
            >
               <div className="fv-row mb-7">
              <div className="fw-bold mt-5">Ad Soyad</div>

              <div className="text-gray-600">{modalData.user.first_name } {modalData.user.last_name}</div>
              </div>
              <div className="fv-row mb-7">
              <div className="fw-bold mt-5">Telefon Numarası</div>

              <div className="text-gray-600">{modalData.user.phone_number}</div>
              </div>
              <div className="fv-row mb-7">
              <div className="fw-bold mt-5">Stake Bakiyesi</div>

              <div className="text-gray-600">{modalData.user.staked_balance} IZMR</div>
              </div>
              <div className="fv-row mb-7">
              <Link to={`/users/${modalData.user_id}`} onClick={destroy} className="btn btn-sm btn-light btn-color-info btn-active-light-info px-4 py-2 me-4">Kullanıcıyı Detaylı İncele</Link>

            
              </div>
           
             
            </div>
          </div>

          </div>

         
        </div>
    </div>
  );
};
export default DetailStake;
