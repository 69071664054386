import instance from '../../utils/api/instance'

const get = async() => {
    const response = await instance.get("options")
    if(response.status === 200){
        return response.data;
    }

    return Promise.reject(response.data);
}

const edit = async(data) => {
    const response = await instance.patch("options",data)
    if(response.status === 200){
        return response.data;
    }

    return Promise.reject(response.data);
}

const create = async(data) => {
    const response = await instance.post("options",data)
    if(response.status === 201){
        return response.data;
    }

    return Promise.reject(response.data);
}


const optionsService = {
    get,
    edit,
    create,
    
};

export default optionsService;