import {useSelector} from "react-redux";
import { selectAllPromocodesClaims, selectPromocodesClaimsById } from ".";
export const useStatus = () => useSelector(state=> state.promocodes.status);
export const useError = () => useSelector(state=> state.promocodes.error);
export const useData = () => useSelector(selectAllPromocodesClaims);
export const usePage = () => useSelector(state=>state.promocodes.page);
export const usePages = () => useSelector(state=>state.promocodes.pages);
export const useTotal = () => useSelector(state=>state.promocodes.total);
export const usePerPage = () => useSelector(state=>state.promocodes.per_page);

export const useSelectPromocodesClaimsById = (id) => useSelector(state => selectPromocodesClaimsById(state,id))