import Table from "../../../components/Table";

const PromotionParticipationsContainer = ({ searchable,data,columns, totalPages,pageNumber,perPage,total,nextPageFn,prevPageFn }) => {


  return (
    <div class="row g-5 g-xl-8">
      <Table searchable={searchable} columns={columns} data={data} totalPages={totalPages} pageNumber={pageNumber} perPage={perPage} total={total} nextPageFn={nextPageFn} prevPageFn={prevPageFn}/>
    </div>
  );
};
export default PromotionParticipationsContainer;
