import {useState,useRef,useEffect} from "react"
import {login} from "../../stores/login/actions"
import { MENU } from "../../utils/consts/menu";
import { NavLink,Link,useNavigate } from "react-router-dom";
import {useSidebarVisibility} from "../../stores/app/hooks";
import {setSidebarVisibility} from "../../stores/app/actions";

const Sidebar = () => {
  const navigate = useNavigate();
  const ref = useRef();
  const [activeIndex, setActiveIndex] = useState(null)
    const sidebarVisibility = useSidebarVisibility();


  const handleMenuClick = index => {
    if(index === activeIndex){
      setActiveIndex(null);
    }else{
      setActiveIndex(index)
    }
  }

  const handleLogout = async () => {
     login.logout();
    navigate("/login")
  }

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setSidebarVisibility(false);
    }
  }

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);

  return (
    <div ref={ref} id="sidebar" className={`aside aside-extended ${sidebarVisibility === true ? "drawer drawer-start drawer-on" : ""}`}>
      <div className="aside-primary d-flex flex-column align-items-lg-center flex-row-auto">
        <div className="aside-logo d-none d-lg-flex flex-column align-items-center flex-column-auto py-10">
          <Link to="/">
            <svg
              className="h-35px"
              width="76"
              height="60"
              viewBox="0 0 76 60"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              classNameName="absolute left-1/2  -translate-x-1/2 top-[59%] hidden md:block"
            >
              <path
                d="M34.4723 22.8209V60L76 16.9575L58.9631 0H17.0409L0 16.9575L25.6379 43.7356V33.6409L9.94927 17.026L19.9509 7.06831H56.0532L66.0548 17.026L41.5317 42.1479V22.8209L38.002 20L34.4723 22.8209Z"
                fill="#2EBEEF"
              ></path>
            </svg>
          </Link>
        </div>

        <div className="aside-nav d-flex flex-column align-items-center flex-column-fluid w-100 pt-5 pt-lg-0">
          <div className="mb-5 scroll-ms px-5">
            <ul className="nav flex-column w-100" id="kt_aside_nav_tabs">
              <li className="izmr-admin-text text-dark fw-bold text-primary fs-1">
                IZMR Admin
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="aside-secondary d-flex flex-row-fluid">
        <div className="aside-workspace my-5 p-5" id="kt_aside_wordspace">
          <div className="d-flex h-100 flex-column">
            <div
              className="flex-column-fluid hover-scroll-y"

            >
              <div className="tab-content">
                <div className="tab-pane fade  active show" role="tabpanel">
                  <div
                    className="menu menu-column menu-fit menu-rounded menu-title-gray-600 menu-icon-gray-400 menu-state-primary menu-state-icon-primary menu-state-bullet-primary menu-arrow-gray-500 fw-semibold fs-3 px-6 my-5 my-lg-0"

                  >
                    <div id="kt_aside_menu_wrapper" className="menu-fit">
                      {MENU.map(({ path, title, icon, children }, index) =>
                        children ? (
                          <div key={index} className={`menu-item menu-accordion ${activeIndex === index ? 'show hover': ''}`} onClick={()=>handleMenuClick(index)} >
                            <span className="menu-link">
                              <span className="menu-icon">{icon}</span>
                              <span className="menu-title">{title}</span>
                              <span className="menu-arrow"></span>
                            </span>
                            {children.map(({ path, title }, index) => (
                              <div key={index} className="menu-sub menu-sub-accordion" >
                                <div className="menu-item">
                                  <NavLink className="menu-link" to={path}>
                                    <span className="menu-bullet">
                                      <span className="bullet bullet-dot"></span>
                                    </span>
                                    <span className="menu-title">{title}</span>
                                  </NavLink>
                                </div>
                              </div>
                            ))}
                          </div>
                        ) : (
                          <div key={index} className="menu-item">
                            <NavLink className="menu-link" to={path}>
                              <span className="menu-icon">{icon}</span>
                              <span className="menu-title">{title}</span>
                            </NavLink>
                          </div>
                        )
                      )}

                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div>
            <p style={{width:"100%"}} className="btn btn-light-danger" onClick={()=>handleLogout()}>Çıkış Yap</p>
            </div>
          </div>
        </div>
      </div>


    </div>
  );
};
export default Sidebar;
