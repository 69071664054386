import { useEffect } from "react"
import Wrapper from "../../../components/Wrapper"
import AirdropsEditContainer from "../../../containers/Airdrops/Edit"
import { useParams } from "react-router-dom"
import {useAirdropById, useData} from "../../../stores/airdrops/hooks"
import { airdrops } from "../../../stores/airdrops/actions"
const PromotionEdit = () => {
  const {id} = useParams();
  const airdrop = useAirdropById(id)
  const data = useData();
  useEffect(()=>{
    if(!data.length > 0 ){
        airdrops.get()
    }
},[])
  return (
    <Wrapper title="Promosyonlar" path="Anasayfa/Promosyonlar/Güncelle">
        <AirdropsEditContainer data={airdrop} id={id}/>
    </Wrapper>
  )
}
export default PromotionEdit