import { Menu, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { apperance, getApperanceElem } from "../../utils/consts/apperance";
import { setTheme,setSidebarVisibility } from "../../stores/app/actions";
import {useSidebarVisibility} from "../../stores/app/hooks";
import { Link } from "react-router-dom";
import {useDispatch} from "react-redux";

const Wrapper = ({ title, path, children }) => {
    const dispatch = useDispatch();
    const sidebarVisibility = useSidebarVisibility();
  const pathParts = path.split("/").filter(Boolean);

  const homeLink = pathParts.length > 0 ? `/${pathParts[0]}` : "/";

  const openSidebar = () => {
    dispatch(setSidebarVisibility(!sidebarVisibility));
  }
  return (
    <>
      <div className="header">
        <div className="container-xxl d-flex align-items-center justify-content-between">
          <div className="page-title d-flex flex-column align-items-start justify-content-center flex-wrap mt-n5 mt-lg-0 me-lg-2 pb-2 pb-lg-0">
            <h1 className="text-dark fw-bold my-0 fs-2">{title}</h1>

            <ul className="breadcrumb fw-semibold fs-base my-1">
              <li className="breadcrumb-item">
                <Link to={homeLink} className="text-muted text-hover-primary">
                  {pathParts.length > 0 ? pathParts[0] : "IZMR Admin"}
                </Link>
              </li>
              {pathParts.slice(1).map((part, index) => {
                const isLast = index === pathParts.length - 2;
                const to = "/" + pathParts.slice(0, index + 2).join("/");

                return (
                  <li
                    key={to}
                    className={`breadcrumb-item ${isLast ? "text-dark" : ""}`}
                  >
                    {!isLast ? (
                      <Link to={to} className="text-muted text-hover-primary">
                        {part}
                      </Link>
                    ) : (
                      <span className="text-dark">{part}</span>
                    )}
                  </li>
                );
              })}
            </ul>
          </div>

          <div className="d-flex d-lg-none align-items-center ms-n4 me-2">
            <div onClick={() => openSidebar()} className="btn btn-icon btn-active-icon-primary">
              <i className="ki-duotone ki-abstract-14 fs-1">
                <span className="path1"></span>
                <span className="path2"></span>
              </i>
            </div>

            <div className="d-flex align-items-center">
              <a href="index.html">
                <svg
                  width="76"
                  height="60"
                  viewBox="0 0 76 60"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-35px absolute left-1/2  -translate-x-1/2 top-[59%] hidden md:block"
                >
                  <path
                    d="M34.4723 22.8209V60L76 16.9575L58.9631 0H17.0409L0 16.9575L25.6379 43.7356V33.6409L9.94927 17.026L19.9509 7.06831H56.0532L66.0548 17.026L41.5317 42.1479V22.8209L38.002 20L34.4723 22.8209Z"
                    fill="#2EBEEF"
                  ></path>
                </svg>
              </a>
            </div>
          </div>

          <div className="d-flex flex-shrink-0">
            <Menu
              as="div"
              style={{ position: "relative" }}
              className="d-flex align-items-center ms-3"
            >
              <Menu.Button
                className={`btn btn-icon flex-center bg-body btn-color-gray-600 btn-active-color-primary h-40px`}
              >
                <i className="ki-duotone ki-night-day theme-light-show fs-2">
                  <span className="path1"></span>
                  <span className="path2"></span>
                  <span className="path3"></span>
                  <span className="path4"></span>
                  <span className="path5"></span>
                  <span className="path6"></span>
                  <span className="path7"></span>
                  <span className="path8"></span>
                  <span className="path9"></span>
                  <span className="path10"></span>
                </i>
                <i className="ki-duotone ki-moon theme-dark-show fs-2">
                  <span className="path1"></span>
                  <span className="path2"></span>
                </i>
              </Menu.Button>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items
                  className={`themeChanger menu  menu-column menu-rounded menu-title-gray-700 menu-icon-gray-500 menu-active-bg menu-state-color fw-semibold py-4 fs-base w-150px`}
                >
                  {apperance.map(({ key, value }, index) => (
                    <Menu.Item>
                      <div
                        onClick={() => {
                          setTheme(key);
                        }}
                        className="menu-item px-3 my-0"
                      >
                        {getApperanceElem(key)}
                      </div>
                    </Menu.Item>
                  ))}
                </Menu.Items>
              </Transition>
            </Menu>
          </div>
        </div>
      </div>
      <div className="content d-flex flex-column flex-column-fluid">
        <div className="container-xxl">{children}</div>
      </div>
    </>
  );
};
export default Wrapper;
