import * as Yup from 'yup';

export const createPromoScheme = Yup.object().shape({
     title:Yup.string().required("Bu alan boş bırakılamaz"),
     brand_image:Yup.string().required("Bu alan boş bırakılamaz"),
    
     cover_image:Yup.string().required("Bu alan boş bırakılamaz"),
     description:Yup.string().required("Bu alan boş bırakılamaz"),
     start_date:Yup.string().required("Bu alan boş bırakılamaz"),
    claim_count:Yup.string().required("Bu alan boş bırakılamaz"),
});