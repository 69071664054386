import { useEffect,useRef } from "react";
import { Outlet } from "react-router-dom";
import Sidebar from "../../components/Sidebar";
import { useTheme } from "../../stores/app/hooks";
import useColorScheme from "../../hooks/use-color-scheme";
import { useModals } from "../../stores/modal/hooks";
import Modals from "../../modals/index"



const MainLayout = () => {
  const theme = useTheme()
  const modals = useModals();
	const {colorScheme} = useColorScheme()

  useEffect(() => {
		if (theme === 'default') {
			document.documentElement.setAttribute("data-bs-theme",colorScheme)
		} else {
			document.documentElement.setAttribute("data-bs-theme",theme)
		}
	}, [theme, colorScheme])


    return (
  <>
  {modals.length > 0 && <Modals/>}
    <div  className="header-fixed header-tablet-and-mobile-fixed aside-fixed aside-secondary-enabled">
      <div className="d-flex flex-column flex-root">
        <div className="page d-flex flex-row flex-column-fluid">
          <Sidebar />
          <div className="wrapper d-flex flex-column flex-row-fluid">
            <Outlet />
            <div className="footer py-4 d-flex flex-lg-column">
              <div className="container-xxl d-flex flex-column flex-md-row flex-stack">
                <div className="text-dark order-2 order-md-1">
                  <span className="text-gray-400 fw-semibold me-1">2023 | </span>
                  <a
                    href="https://izmirtoken.io"
                    target="_blank"
                    class="text-muted text-hover-primary fw-semibold me-2 fs-6"
                  >
                    Izmir Token
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </>
  );
};
export default MainLayout;
