import { useEffect } from "react";
import Wrapper from "../../../components/Wrapper";
import { Menu } from "@headlessui/react";
import StakeOptionsContainer from "../../../containers/Stakes/Options";
import { modal } from "../../../stores/modal/actions";
import { useSearchParams } from "react-router-dom"
import { stakes } from "../../../stores/stakes/actions";
import {
  useOptionsData,
  useEditOptionStatus,
  useListPage,
  useListPages,
  useListPerPage,
  useListTotal
} from "../../../stores/stakes/hooks";
import { toast } from "sonner";
const StakeOptions = () => {
  let [searchParams, setSearchParams] = useSearchParams();

  const data = useOptionsData();
  const editStatus = useEditOptionStatus();
  const page = useListPage();
  const pages = useListPages();
  const perPage = useListPerPage();
  const total = useListTotal();

  const changeStatus = (id, status) => {
    stakes.edit({
      id: id,
      enabled: status,
    });
  };

  const handleNextPage = (page) => {
    setSearchParams({page:page+1})
  }

  const handlePrevPage = (page) => {
    setSearchParams({page:page-1})
  }

  useEffect(() => {
    stakes.options({page:searchParams.get("page")});
  }, [searchParams]);

  useEffect(() => {
    const messages = {
      pending: { type: "loading", text: "Güncelleniyor..." },
      fulfilled: { type: "success", text: "Güncellendi!" },
      rejected: { type: "error", text: "Bir hata oluştu!" },
    };

    const message = messages[editStatus];
    if (message) {
      toast[message.type](message.text);
    }
  }, [editStatus]);

  const columns = [
    {
      header: "ID",
      accessorKey: "id",
    },
    {
      header: "Min Miktar",
      accessorKey: "min_amount",
    },
    {
      header: "Max Miktar",
      accessorFn: (row) =>
        `${row?.max_amount === null ? "Limitsiz" : row?.max_amount}`,
    },
    {
      header: "Kilit Açılma Günü",
      accessorFn: (row) => `${row?.release_days} Gün Sonra`,
    },
    {
      header: "Ödül Yüzdesi",
      accessorFn: (row) => `%${row?.reward_percent}`,
    },
    {
      header: "Durum",
      cell: (cell) =>
        cell.row.original?.enabled === true ? (
          <a
            href="#"
            class="btn btn-sm btn-light-success btn-color-success btn-active-light-success px-4 py-2 me-4"
          >
            Aktif
          </a>
        ) : (
          <a
            href="#"
            class="btn btn-sm btn-light-danger btn-color-danger btn-active-light-danger px-4 py-2 me-4"
          >
            Pasif
          </a>
        ),
    },

    {
      header: "İşlemler",
      cell: (cell) => (
        <Menu as="div" style={{ position: "relative" }}>
          <Menu.Button
            type="button"
            className="btn btn-sm btn-icon btn-color-primary btn-active-light-primary"
            data-kt-menu-trigger="click"
            data-kt-menu-placement="bottom-end"
          >
            <i className="ki-duotone ki-pencil fs-6">
              <span className="path1"></span>
              <span className="path2"></span>
              <span className="path3"></span>
              <span className="path4"></span>
            </i>
          </Menu.Button>

          <Menu.Items
            className="themeChanger menu menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-semibold w-200px"
            data-kt-menu="true"
          >
            <div className="menu-item px-3">
              <div className="menu-content fs-6 text-start text-dark fw-bold px-3 py-4">
                Stake Opsiyon İşlemleri
              </div>
            </div>

            <div className="separator mb-3 opacity-75"></div>

            <Menu.Item as="div" className="menu-item px-3">
              <span onClick={()=>modal.append("modal.edit-stake-option",{id:cell.row.original.id,min_amount:cell.row.original.min_amount,max_amount:cell.row.original.max_amount,release_days:cell.row.original.release_days,reward_percent:cell.row.original.reward_percent,enabled:cell.row.original.enabled})} className="menu-link px-3">Düzenle</span>
            </Menu.Item>
            {
                cell.row.original?.enabled === true ? ( <Menu.Item as="div" className="menu-item px-3 ">
              <span
                  onClick={() => changeStatus(cell.row.original.id, false)}
                  className="menu-link px-3 text-danger "
              >
                Pasif Hale Getir
              </span>
                </Menu.Item>) : (   <Menu.Item as="div" className="menu-item px-3">
              <span
                  onClick={() => changeStatus(cell.row.original.id, true)}
                  className="menu-link px-3"
              >
                Yayınla Al
              </span>
                </Menu.Item>)
            }


            <div className="separator mt-3 opacity-75"></div>
          </Menu.Items>
        </Menu>
      ),
    },
  ];
  return (
    <Wrapper title="Stake Opsiyonları" path="Stake/Opsiyonlar">
      <StakeOptionsContainer data={data} columns={columns} totalPages={pages} pageNumber={page} perPage={perPage} total={total} nextPageFn={() => handleNextPage(page)} prevPageFn={()=>handlePrevPage(page)} />
    </Wrapper>
  );
};
export default StakeOptions;
