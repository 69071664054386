import { useEffect } from "react";
import Wrapper from "../../../components/Wrapper";
import PromotionCodesContainer from "../../../containers/Promotions/Codes";
import { promocodes } from "../../../stores/promocodes/actions";
import { useData,useEditStatus,usePage,usePages,usePerPage,useTotal } from "../../../stores/promocodes/hooks";
import { Menu } from "@headlessui/react";
import { toast } from "sonner";
import {modal} from "../../../stores/modal/actions"
import { useSearchParams } from "react-router-dom";

const PromotionCodes = () => {
  let [searchParams, setSearchParams] = useSearchParams();
  const data = useData();
  const page = usePage();
  const pages = usePages();
  const perPage = usePerPage();
  const total = useTotal();

  const editStatus = useEditStatus();

  const handleNextPage = (page) => {
    setSearchParams({page:page+1})
  }

  const handlePrevPage = (page) => {
    setSearchParams({page:page-1})
  }

  useEffect(() => {
    promocodes.get({page:searchParams.get("page")});
    return () => {
      promocodes.reset()
    }
  }, [searchParams]);


  useEffect(() => {
    const messages = {
      pending: { type: 'loading', text: "Güncelleniyor..." },
      fulfilled: { type: 'success', text: "Güncellendi!" },
      rejected: { type: 'error', text: "Bir hata oluştu!" }
    };
  
    const message = messages[editStatus];
    if (message) {
      toast[message.type](message.text);
    }
  }, [editStatus]);

  const changeStatus = (id, status) => {
    promocodes.edit({
      id:id,
      active:status
    })
  };

  const columns = [
    {
      header: "ID",
      accessorKey: "id",
    },
    {
      header: "Promosyon Adı",
      accessorKey: "promo.title",
    },
    {
      header: "Promosyon Kodu",
      accessorKey: "code",
    },
    {
      header: "Kalan Katılım Sayısı",
      accessorKey: "remaining_claims",
    },
    {
      header: "Durum",
      cell: (cell) =>
        cell.row.original.active === true ? (
          <a
            href="#"
            class="btn btn-sm btn-light-success btn-color-success btn-active-light-success px-4 py-2 me-4"
          >
            Aktif
          </a>
        ) : (
          <a
            href="#"
            class="btn btn-sm btn-light-danger btn-color-danger btn-active-light-danger px-4 py-2 me-4"
          >
            Pasif
          </a>
        ),
    },

    {
      header: "İşlemler",
      cell: (cell) => (
        <Menu as="div" style={{ position: "relative" }}>
          <Menu.Button
            type="button"
            className="btn btn-sm btn-icon btn-color-primary btn-active-light-primary"
            data-kt-menu-trigger="click"
            data-kt-menu-placement="bottom-end"
          >
            <i className="ki-duotone ki-pencil fs-6">
              <span className="path1"></span>
              <span className="path2"></span>
              <span className="path3"></span>
              <span className="path4"></span>
            </i>
          </Menu.Button>

          <Menu.Items
            className="themeChanger menu menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-semibold w-200px"
            data-kt-menu="true"
          >
            <div className="menu-item px-3">
              <div className="menu-content fs-6 text-start text-dark fw-bold px-3 py-4">
                Promosyon Kodu İşlemleri
              </div>
            </div>

            <div className="separator mb-3 opacity-75"></div>

            <Menu.Item as="div" className="menu-item px-3">
              <span onClick={()=>modal.append("modal.edit-promo-code",{id:cell.row.original.id,remaining_claims:cell.row.original.remaining_claims})} className="menu-link px-3">Düzenle</span>
            </Menu.Item>
            {cell.row.original.active === false && (
              <Menu.Item as="div" className="menu-item px-3">
                <span
                  onClick={() => changeStatus(cell.row.original.id, true)}
                  className="menu-link px-3"
                >
                  Yayınla Al
                </span>
              </Menu.Item>
            )}
            {cell.row.original.active === true && (
              <Menu.Item as="div" className="menu-item px-3 ">
                <span
                  onClick={() => changeStatus(cell.row.original.id, false)}
                  className="menu-link px-3 text-danger "
                >
                  Sonlandır
                </span>
              </Menu.Item>
            )}
            <div className="separator mt-3 opacity-75"></div>
          </Menu.Items>
        </Menu>
      ),
    },
  ];

  return (
    <Wrapper
      title="Promosyon Kodları"
      path="Anasayfa/Promosyonlar/Promosyon Kodları"
    >
      <PromotionCodesContainer data={data} columns={columns} totalPages={pages} pageNumber={page} perPage={perPage} total={total} nextPageFn={() => handleNextPage(page)} prevPageFn={()=>handlePrevPage(page)}  />
    </Wrapper>
  );
};
export default PromotionCodes;
