import { useEffect } from "react";

import { partners } from "../../stores/partners/actions";
import { useData } from "../../stores/partners/hooks";

import Wrapper from "../../components/Wrapper";
import PartnersContainer from "../../containers/Partners";
import { modal } from "../../stores/modal/actions";
const Partners = () => {
  useEffect(() =>{
    partners.get()
  },[])
  const data = useData()
  const columns = [
    {
      header: "ID",
      accessorKey: "id",
    },
    {
      header: "İş Ortağı Adı",
      accessorKey: "name",
    },
    {
      header: "Api Key",
      accessorKey: "apikey",
    },

    {
      header: "Reset",
      cell: (cell) => (
        <a onClick={()=>modal.append("modal.reset-api",{id:cell.row.original.id,name:cell.row.original.name})} className="btn btn-sm btn-light btn-flex btn-center btn-active-light-primary">
          Reset API Key
        </a>
      ),
    },
  ];

  return (
    <Wrapper title="İş Ortakları" path="Anasayfa/İş Ortakları">
      <PartnersContainer searchable={false} data={data} columns={columns} />
    </Wrapper>
  );
};
export default Partners;
