import { Field, ErrorMessage } from "formik";

const Input = ({name,placeholder,type="text",...props}) => {
  return (
    <>
      <Field
        type={type}
        placeholder={placeholder}
        name={name}

        className="form-control form-control-solid"
        {...props}
      />
      <ErrorMessage
        component="small"
        name={name}
        className="badge badge-light-danger mt-2"
      />
    </>
  );
};
export default Input;
