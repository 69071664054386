import { useEffect } from "react";
import Wrapper from "../../components/Wrapper";
import OptionsContainer from "../../containers/Options"
import { modal } from "../../stores/modal/actions";

import { options } from "../../stores/options/actions";
import {
  useOptionsData,

} from "../../stores/options/hooks";

const Options = () => {
  useEffect(() => {
    options.get();
  }, []);
  const data = useOptionsData();



  const columns = [
    {
      header: "Key",
      accessorKey: "key",
    },
    {
      header: "Açıklama",
      accessorKey: "description",
    },
    {
      header: "Değer",
      accessorFn: (row) =>
        `${row.value === true ? "Aktif" : row.value === false ? "Pasif" : row.value}`,
    },
    {
      header: "Tip",
      accessorKey: "type",
    },
    {
      header: "Görüntülenebilir",
      accessorFn: (row) => `${row.public === true ? "Evet" : "Hayır"}`,
    },


    {
      header: "Düzenle",
      cell: (cell) => (
        <button
            onClick={()=>modal.append("modal.edit-option",{...cell.row.original})}
            className="btn btn-sm btn-icon btn-color-primary btn-active-light-primary btn-light-primary"
            data-kt-menu-trigger="click"
            data-kt-menu-placement="bottom-end"
          >
            <i className="ki-duotone ki-pencil fs-6">
              <span className="path1"></span>
              <span className="path2"></span>
              <span className="path3"></span>
              <span className="path4"></span>
            </i>
          </button>
      ),
    },
  ];
  return (
    <Wrapper title="İzmir Token Opsiyonları" path="Anasayfa/Opsiyonlar">
     <OptionsContainer searchable={false} data={data} columns={columns} />
    </Wrapper>
  );
};
export default Options;
