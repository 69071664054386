import { createSlice,createAsyncThunk } from "@reduxjs/toolkit";
import loginService from "./service";


export const _login = createAsyncThunk("auth/login", async(data, {rejectWithValue}) => {
    try {
        const response = await loginService.login(data);
      
        return response
    } catch (error) {
         const message = error.response.data
       
        return rejectWithValue(message);
    }
});

export const _logout = createAsyncThunk("auth/logout", async(_, {rejectWithValue}) => {
    loginService.logout();
});

const initialState = {
    response:"",
    status:'idle',
    error:"",
    logoutStatus:'idle',
    isLogin:false
};


const login = createSlice({
    name:"login",
    initialState,
    reducers:{
        _reset: (state) => {
            state.status = 'idle';
            state.error = "";
        }
    },
    extraReducers:(builder) => {
        builder
        .addCase(_login.pending, (state) => {
            state.status = 'pending'
        })
        .addCase(_login.fulfilled, (state,action) =>{
            state.status = "fulfilled";
            state.response = action.payload.response;
            state.isLogin = true;
        })
        .addCase(_login.rejected, (state,action) => {
            state.status = "rejected"
            state.error = action.payload.errorcode;
            state.isLogin = false;
        })
        .addCase(_logout.pending, (state) => {
            state.logoutStatus = 'pending'
        })
        .addCase(_logout.fulfilled, (state) =>{
            state.logoutStatus = "fulfilled"
            state.isLogin = false;
          
        })
        .addCase(_logout.rejected, (state) => {
            state.logoutStatus = "rejected"
          
        })
    }
})

export const {_reset} = login.actions;
export default login.reducer
