import Table from "../../components/Table"
import {modal} from "../../stores/modal/actions"



const PartnersContainer = ({searchable,data,columns}) => {


  return (
    <Table
        searchable={false}
    actionText="Yeni İş Ortağı"
    action={true}
    actionFn={()=>modal.append('modal.create-merchant')}
      data={data}
      columns={columns}
  />
  )
}
export default PartnersContainer
