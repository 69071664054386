import { useEffect, useState } from "react";
import { airdrops } from "../../../stores/airdrops/actions";
import {
  useCreateStatus,

} from "../../../stores/airdrops/hooks";

import { useNavigate } from "react-router-dom";
import { toast } from "sonner";
const AirdropsCreateContainer = () => {
  const navigate = useNavigate();
  const createStatus = useCreateStatus();
  const [inputType, setInputType] = useState("");
  const [airdropStatus,setAirdropStatus] = useState();
  const [fields,setFields] = useState({
    name:"",
    description:"",
    start_date:"",
    end_date:"",
    release_date:"",
    method:"manual",
    reward_amount:"",
   
  });





  const changeInputType = (type) => {
    setInputType(type);
  };

  const handleChangeFields = (e) => {
    setFields({
      ...fields,
      [e.target.name]:e.target.value
    })
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    
    airdrops.create(
      {
        ...fields,
        enabled:"1" ? true : false,
        ask_inputs:{
          [inputType]:{
            name:inputType,
            required:true,
            type:"text",
            regex: "^.{6,80}$",
          }
        }
      }
    )
  };

  useEffect(()=>{
    if(createStatus==="fulfilled"){
      toast.success("Airdrop Eklendi")
      setTimeout(()=>{
        navigate("/airdrops/list")
      },1500)
    }
    if(createStatus === "rejected"){
      toast.error("Airdrop Eklenemedi")
    }
  },[createStatus])



  return (
    <form
      onSubmit={handleSubmit}
      className="form d-flex flex-column flex-lg-row fv-plugins-bootstrap5 fv-plugins-framework"
    >
      <div className="d-flex flex-column gap-7 gap-lg-10 w-100 w-lg-300px mb-7 me-lg-10">
        <div className="card card-flush py-4">
          <div className="card-header">
            <div className="card-title">
              <h2>Durum</h2>
            </div>
          </div>

          <div className="card-body pt-0">
          <select
             
             name="enabled"
             className="form-select mb-2"
             placeholder=""
             value={airdropStatus}
             onChange={(e) => setAirdropStatus(e.target.value)}
           >
               <option value={"1"}>Aktif</option>
               <option value={"0"}>Pasif</option>
           </select>
          
          </div>
        </div>
        <div className="card card-flush py-4">
          <div className="card-header">
            <div className="card-title">
              <h2>Başlangıç Tarihi</h2>
            </div>
          </div>

          <div className="card-body pt-0">
            <input
              type="datetime-local"
              name="start_date"
              className="form-control mb-2"
              placeholder=""
              value={fields.start_date}
              onChange={handleChangeFields}
            />
          </div>
        </div>
        <div className="card card-flush py-4">
          <div className="card-header">
            <div className="card-title">
              <h2>Bitiş Tarihi</h2>
            </div>
          </div>

          <div className="card-body pt-0">
          <input
              type="datetime-local"
              name="end_date"
              className="form-control mb-2"
              placeholder=""
              value={fields.end_date}
              onChange={handleChangeFields}
            />
          </div>
        </div>
        <div className="card card-flush py-4">
          <div className="card-header">
            <div className="card-title">
              <h2>Ödüllerin Dağıtılma Tarihi</h2>
            </div>
          </div>

          <div className="card-body pt-0">
          <input
              type="datetime-local"
              name="release_date"
              className="form-control mb-2"
              placeholder=""
              value={fields.release_date}
              onChange={handleChangeFields}
            />
          </div>
        </div>
      </div>

      <div className="d-flex flex-column flex-row-fluid gap-7 gap-lg-10">
        <div className="tab-content">
          <div
            className="tab-pane fade active show"
            id="kt_ecommerce_add_product_general"
            role="tab-panel"
          >
            <div className="d-flex flex-column gap-7 gap-lg-10">
              <div className="card card-flush py-4">
                <div className="card-header">
                  <div className="card-title">
                    <h2>Genel</h2>
                  </div>
                </div>

                <div className="card-body pt-0">
                  <div className="mb-10 fv-row fv-plugins-icon-container">
                    <label className="required form-label">Airdrop Adı</label>

                    <input
                      type="text"
                      name="name"
                      className="form-control mb-2"
                      placeholder=""
                      value={fields.name}
                      onChange={handleChangeFields}
                    />

                    <div className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback"></div>
                  </div>

                  <div>
                    <label className="form-label">Airdrop Açıklaması</label>

                    <textarea
                      name="description"
                      value={fields.description}
                      onChange={handleChangeFields}
                      class="form-control form-control-lg "
                      rows="3"
                    ></textarea>
                  </div>
                </div>
              </div>
              <div className="card card-flush py-4">
                <div className="card-header">
                  <div className="card-title">
                    <h2>Input Tipi</h2>
                  </div>
                </div>

                <div className="card-body pt-0">
                  <div
                    class="row row-cols-1 row-cols-md-4 row-cols-lg-1 row-cols-xl-4 g-9"
                    data-kt-buttons="true"
                    data-kt-buttons-target="[data-kt-button='true']"
                    data-kt-initialized="1"
                  >
                    <div class="col">
                      <label
                        class={`btn btn-outline btn-outline-dashed btn-active-light-primary ${
                          inputType === "username" ? "active" : ""
                        } d-flex text-start p-6`}
                      >
                        <span class="form-check form-check-custom form-check-solid form-check-sm align-items-start mt-1">
                          <input
                            class="form-check-input"
                            type="radio"
                            name="discount_option"
                            value="username"
                            onChange={(e) => changeInputType(e.target.value)}
                          />
                        </span>

                        <span class="ms-5">
                          <span class="fs-4 fw-bold text-gray-800 d-block">
                            Kullanıcı Adı
                          </span>
                        </span>
                      </label>
                    </div>
                    <div class="col">
                      <label
                        class={`btn btn-outline btn-outline-dashed btn-active-light-primary  ${
                          inputType === "email" ? "active" : ""
                        } d-flex text-start p-6`}
                      >
                        <span class="form-check form-check-custom form-check-solid form-check-sm align-items-start mt-1">
                          <input
                            class="form-check-input"
                            type="radio"
                            name="discount_option"
                            value="email"
                            onChange={(e) => changeInputType(e.target.value)}
                          />
                        </span>

                        <span class="ms-5">
                          <span class="fs-4 fw-bold text-gray-800 d-block">
                            Email
                          </span>
                        </span>
                      </label>
                    </div>
                    <div class="col">
                      <label
                        class={`btn btn-outline btn-outline-dashed btn-active-light-primary ${
                          inputType === "link" ? "active" : ""
                        }  d-flex text-start p-6`}
                      >
                        <span class="form-check form-check-custom form-check-solid form-check-sm align-items-start mt-1">
                          <input
                            class="form-check-input"
                            type="radio"
                            name="discount_option"
                            value="link"
                            onChange={(e) => changeInputType(e.target.value)}
                          />
                        </span>

                        <span class="ms-5">
                          <span class="fs-4 fw-bold text-gray-800 d-block">
                            Link
                          </span>
                        </span>
                      </label>
                    </div>
                    <div class="col">
                      <label
                        class={`btn btn-outline btn-outline-dashed btn-active-light-primary ${
                          inputType === "other" ? "active" : ""
                        }  d-flex text-start p-6`}
                        data-kt-button="true"
                      >
                        <span class="form-check form-check-custom form-check-solid form-check-sm align-items-start mt-1">
                          <input
                            class="form-check-input"
                            type="radio"
                            name="discount_option"
                            value="other"
                            onChange={(e) => changeInputType(e.target.value)}
                          />
                        </span>

                        <span class="ms-5">
                          <span class="fs-4 fw-bold text-gray-800 d-block">
                            Diğer
                          </span>
                        </span>
                      </label>
                    </div>
                  </div>
                  {inputType === "other" && (
                    <div>
                      <div className="mb-10 mt-10 fv-row fv-plugins-icon-container">
                        <label className="required form-label">Input Adı</label>

                        <input
                          type="text"
                          name="product_name"
                          className="form-control mb-2"
                          placeholder=""
                          value=""
                        />

                        <div className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback"></div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="card card-flush py-4">
                <div className="card-header">
                  <div className="card-title">
                    <h2>Ödül</h2>
                  </div>
                </div>

                <div className="card-body pt-0">
                  <div className="mb-10 fv-row fv-plugins-icon-container">
                    <label className="required form-label">Ödül Miktarı</label>

                    <input
                      type="text"
                      name="reward_amount"
                      className="form-control mb-2"
                      placeholder=""
                      value={fields.reward_amount}
                      onChange={handleChangeFields}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="d-flex justify-content-end">
          <a
            href="../../demo7/dist/apps/ecommerce/catalog/products.html"
            id="kt_ecommerce_add_product_cancel"
            className="btn btn-light me-5"
          >
            İptal
          </a>

          <button
            type="submit"
            id="kt_ecommerce_add_product_submit"
            className="btn btn-primary"
            disabled={createStatus === "pending"}
          >
            {createStatus === "pending" ? (
              <span style={{ display: "block" }} className="indicator-progress">
                Lütfen Bekleyiniz...
                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
              </span>
            ) : (
              <span className="indicator-label">Oluştur</span>
            )}
          </button>
        </div>
      </div>
    </form>
  );
};
export default AirdropsCreateContainer;
