const HomeContainer = ({data}) => {
  return (
    <div className="row gy-5 g-xl-10">
    <div className="col-sm-8 col-xl-3 mb-xl-10">
      <div className="card h-lg-100">
        <div className="card-body d-flex justify-content-between align-items-start flex-column">
          <div className="m-0">
            <i className="ki-duotone ki-user fs-2hx text-gray-600">
              <span className="path1"></span>
              <span className="path2"></span>
            </i>
          </div>

          <div className="d-flex flex-column my-7">
            <span className="fw-semibold fs-3x text-gray-800 lh-1 ls-n2">
              {data?.user_count || 0}
            </span>

            <div className="m-0">
              <span className="fw-semibold fs-6 text-gray-400">
                Kullanıcı
              </span>
            </div>
          </div>

    
        </div>
      </div>
    </div>

    <div className="col-sm-8 col-xl-3 mb-xl-10">
      <div className="card h-lg-100">
        <div className="card-body d-flex justify-content-between align-items-start flex-column">
          <div className="m-0">
            <i className="ki-duotone ki-wallet fs-2hx text-gray-600">
                <span className="path1"></span>
                <span className="path2"></span>
                <span className="path3"></span>
                <span className="path4"></span>
            </i>
          </div>

          <div className="d-flex flex-column my-7">
            <span className="fw-semibold fs-3x text-gray-800 lh-1 ls-n2">
              {data?.user_wallets_balance_total}
            </span>

            <div className="m-0">
              <span className="fw-semibold fs-6 text-gray-400">
                Kullanıcı Toplam Bakiye (IZMR)
              </span>
            </div>
          </div>

       
        </div>
      </div>
    </div>

    <div className="col-sm-8 col-xl-3 mb-xl-10">
      <div className="card h-lg-100">
        <div className="card-body d-flex justify-content-between align-items-start flex-column">
          <div className="m-0">
            <i className="ki-duotone ki-rocket fs-2hx text-gray-600">
              <span className="path1"></span>
              <span className="path2"></span>
            </i>
          </div>

          <div className="d-flex flex-column my-7">
            <span className="fw-semibold fs-3x text-gray-800 lh-1 ls-n2">
              {data?.stake_count || 0}
            </span>

            <div className="m-0">
              <span className="fw-semibold fs-6 text-gray-400">
                Stake Sayısı
              </span>
            </div>
          </div>

        
        </div>
      </div>
    </div>

    <div className="col-sm-8 col-xl-3 mb-xl-10">
      <div className="card h-lg-100">
        <div className="card-body d-flex justify-content-between align-items-start flex-column">
          <div className="m-0">
          <i className="ki-duotone ki-rocket fs-2hx text-gray-600">
              <span className="path1"></span>
              <span className="path2"></span>
            </i>
          </div>

          <div className="d-flex flex-column my-7">
            <span className="fw-semibold fs-3x text-gray-800 lh-1 ls-n2">
              {data?.pending_stake_count || 0}
            </span>

            <div className="m-0">
              <span className="fw-semibold fs-6 text-gray-400">Bekleyen Stake Sayısı</span>
            </div>
          </div>

         
        </div>
      </div>
    </div>

    <div className="col-sm-8 col-xl-3 mb-5 mb-xl-10">
      <div className="card h-lg-100">
        <div className="card-body d-flex justify-content-between align-items-start flex-column">
          <div className="m-0">
          <i className="ki-duotone ki-rocket fs-2hx text-gray-600">
              <span className="path1"></span>
              <span className="path2"></span>
            </i>
          </div>

          <div className="d-flex flex-column my-7">
            <span className="fw-semibold fs-3x text-gray-800 lh-1 ls-n2">
              {data?.pending_stake_total || 0}
            </span>

            <div className="m-0">
              <span className="fw-semibold fs-6 text-gray-400">Bekleyen Stake Toplamı</span>
            </div>
          </div>

         
        </div>
      </div>
    </div>

    <div className="col-sm-8 col-xl-3 mb-5 mb-xl-10">
      <div className="card h-lg-100">
        <div className="card-body d-flex justify-content-between align-items-start flex-column">
          <div className="m-0">
          <i className="ki-duotone ki-rocket fs-2hx text-gray-600">
              <span className="path1"></span>
              <span className="path2"></span>
            </i>
          </div>

          <div className="d-flex flex-column my-7">
            <span className="fw-semibold fs-3x text-gray-800 lh-1 ls-n2">
              {data?.pending_stake_release_total || 0}
            </span>

            <div className="m-0">
              <span className="fw-semibold fs-6 text-gray-400">Bekleyen Stake Bozdurma Toplamı</span>
            </div>
          </div>

        
        </div>
      </div>
    </div>
    <div className="col-sm-8 col-xl-3 mb-5 mb-xl-10">
      <div className="card h-lg-100">
        <div className="card-body d-flex justify-content-between align-items-start flex-column">
          <div className="m-0">
            <i className="ki-duotone ki-like-2 fs-2hx text-gray-600">
              <span className="path1"></span>
              <span className="path2"></span>
            </i>
          </div>

          <div className="d-flex flex-column my-7">
            <span className="fw-semibold fs-3x text-gray-800 lh-1 ls-n2">
             {data?.promocode_count || 0}
            </span>

            <div className="m-0">
              <span className="fw-semibold fs-6 text-gray-400">Promosyon Kodları</span>
            </div>
          </div>

          
        </div>
      </div>
    </div>

    <div className="col-sm-8 col-xl-3 mb-5 mb-xl-10">
      <div className="card h-lg-100">
        <div className="card-body d-flex justify-content-between align-items-start flex-column">
          <div className="m-0">
            <i className="ki-duotone ki-like-2 fs-2hx text-gray-600">
              <span className="path1"></span>
              <span className="path2"></span>
            </i>
          </div>

          <div className="d-flex flex-column my-7">
            <span className="fw-semibold fs-3x text-gray-800 lh-1 ls-n2">
              {data.promocodeclaim_count || 0}
            </span>

            <div className="m-0">
              <span className="fw-semibold fs-6 text-gray-400">Promosyon Kod Kullanan Sayısı</span>
            </div>
          </div>

         
        </div>
      </div>
    </div>
  </div>
  )
}
export default HomeContainer