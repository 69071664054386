import { useEffect } from "react";
import { partners } from "../../stores/partners/actions";
import { useAddStatus, useAddError } from "../../stores/partners/hooks";
import { Formik, Form } from "formik";
import { addPartnerScheme } from "../../validations";
import Input from "../../components/Input";
import useErrorCode from "../../hooks/useErrorCode";
const CreatePartner = ({ destroy }) => {
  const { e } = useErrorCode();
  const status = useAddStatus();
  const error = useAddError();

  const escFunction = (e) => {
    if (e.key === "Escape") {
      destroy();
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", escFunction, false);

    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, [escFunction]);

  useEffect(()=>{
    return(()=> {
      partners.reset()
    })
  },[])

  return (
    <div class="form" id="kt_modal_add_customer_form">
      <div class="modal-header" id="kt_modal_add_customer_header">
        <h2 class="fw-bold">Yeni İş Ortağı</h2>

        <div
          onClick={destroy}
          id="kt_modal_add_customer_close"
          class="btn btn-icon btn-sm btn-active-icon-primary"
        >
          <i class="ki-duotone ki-cross fs-1">
            <span class="path1"></span>
            <span class="path2"></span>
          </i>
        </div>
      </div>

      <Formik
        validationSchema={addPartnerScheme}
        initialValues={{
          partnerName: "",
        }}
        onSubmit={(values) => {
          partners.create({
            name:values.partnerName
          });
        }}
      >
        <Form>
          <div class="modal-body py-10 px-lg-17">
            {status === "rejected" && (
              <div class="alert alert-danger d-flex align-items-center px-5">
                <div class="d-flex flex-column">
                  <h4 class="mb-1 text-danger">Hata!</h4>

                  <span>{e(error)}</span>
                </div>
              </div>
            )}

            {status === "fulfilled" && (
              <div class="alert alert-success d-flex align-items-center px-5">
                <div class="d-flex flex-column">
                  <h4 class="mb-1 text-success">Başarılı!</h4>

                  <span>{"İş Ortağı Eklendi"}</span>
                </div>
              </div>
            )}
            <div
              class="scroll-y me-n7 pe-7"
              id="kt_modal_add_customer_scroll"
              data-kt-scroll="true"
              data-kt-scroll-activate="{default: false, lg: true}"
              data-kt-scroll-max-height="auto"
              data-kt-scroll-dependencies="#kt_modal_add_customer_header"
              data-kt-scroll-wrappers="#kt_modal_add_customer_scroll"
              data-kt-scroll-offset="300px"
            >
              <div class="fv-row mb-7">
                <label class="required fs-6 fw-semibold mb-2">Adı</label>

                <Input name="partnerName" placeholder="İş Ortağı Adı" />
              </div>
            </div>
          </div>

          <div class="modal-footer flex-center">
            <button
              onClick={destroy}
              type="reset"
              id="kt_modal_add_customer_cancel"
              class="btn btn-light me-3"
            >
              İptal
            </button>

            <button
              type="submit"
              disabled={status === "pending"}
              id="kt_modal_add_customer_submit"
              class="btn btn-primary"
            >
              {status === "pending" ? (
                <span style={{ display: "block" }} class="indicator-progress">
                  Lütfen Bekleyin...
                  <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
              ) : (
                <span class="indicator-label">Kaydet</span>
              )}
            </button>
          </div>
        </Form>
      </Formik>
    </div>
  );
};
export default CreatePartner;
