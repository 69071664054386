import Table from "../../../components/Table"

const AirdropsListContainer = ({ searchQuery, setSearchQuery, searchable,data,columns,totalPages,pageNumber,perPage,total,nextPageFn,prevPageFn}) => {
  return (
    <div class="row g-5 g-xl-8">
        <Table searchQuery={searchQuery} setSearchQuery={setSearchQuery} searchable={searchable} data={data} columns={columns} totalPages={totalPages} pageNumber={pageNumber} perPage={perPage} total={total} nextPageFn={nextPageFn} prevPageFn={prevPageFn}/>
    </div>
  );
};
export default AirdropsListContainer;
