import errorMessages from "../utils/errors/messages.json"

const useErrorCode = () =>  {
    const translate = (errorCode) => {
        return errorMessages[errorCode] || errorCode;
    }

    return {e:translate}
}

export default useErrorCode