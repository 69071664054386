import { useEffect,useState } from "react"
import { Menu } from "@headlessui/react";
import Wrapper from "../../../components/Wrapper"
import AirdropListContainer from "../../../containers/Airdrops/List"
import { airdrops } from "../../../stores/airdrops/actions"
import { useData,useEditStatus,usePage,usePages,usePerPage,useTotal } from "../../../stores/airdrops/hooks"
import { Link,useSearchParams } from "react-router-dom"
import {useDebounce} from "use-debounce";
import { toast } from "sonner";

const AirdropList = () => {
  let [searchParams, setSearchParams] = useSearchParams();
  const [searchQuery, setSearchQuery] = useState("");
  const [debouncedSearchQuery] = useDebounce(searchQuery, 500);
  const data = useData()
  const editStatus = useEditStatus();
  const page=usePage();
  const pages=usePages();
  const perPage = usePerPage();
  const total = useTotal();

  const handleNextPage = (page) => {
    setSearchParams({page:page+1})
  }

  const handlePrevPage = (page) => {
    setSearchParams({page:page-1})
  }

  useEffect(()=>{
    airdrops.get({page:searchParams.get("page")})
    return () => {
      airdrops.reset()
    }
  },[searchParams])

  useEffect(()=>{
    airdrops.get({search:debouncedSearchQuery})
  },[debouncedSearchQuery])


  useEffect(() => {
    const messages = {
      pending: { type: 'loading', text: "Güncelleniyor..." },
      fulfilled: { type: 'success', text: "Güncellendi!" },
      rejected: { type: 'error', text: "Bir hata oluştu!" }
    };

    const message = messages[editStatus];
    if (message) {
      toast[message.type](message.text);
    }
  }, [editStatus]);



  const changeStatus = (id, status) => {
     airdrops.edit({
      id: id,
      enabled: status
    });

  }

  const columns = [
    {
      header: "ID",
      accessorKey:"id",
    },
    {
      header: "Airdrop Adı",
      accessorKey:"name",
    },
    {
      header: "Durum",
      cell:(cell) => cell.row.original.enabled === true ? (<a href="#" class="btn btn-sm btn-light-success btn-color-success btn-active-light-success px-4 py-2 me-4">Aktif</a>) : <a href="#" class="btn btn-sm btn-light-danger btn-color-danger btn-active-light-danger px-4 py-2 me-4">Pasif</a>,
    },


    {
      header: "İşlemler",
      cell: (cell) => (
        <Menu
        as="div"
        style={{position:"relative"}}
      >
      <Menu.Button
            type="button"
            className="btn btn-sm btn-icon btn-color-primary btn-active-light-primary"
            data-kt-menu-trigger="click"
            data-kt-menu-placement="bottom-end"
          >
            <i className="ki-duotone ki-pencil fs-6">
              <span className="path1"></span>
              <span className="path2"></span>
              <span className="path3"></span>
              <span className="path4"></span>
            </i>
          </Menu.Button>

          <Menu.Items
            className="themeChanger menu menu-column menu-rounded menu-gray-800 menu-state-bg-light-primary fw-semibold w-200px"
            data-kt-menu="true"
          >
            <div className="menu-item px-3">
              <div className="menu-content fs-6 text-start text-dark fw-bold px-3 py-4">
                Airdrop İşlemleri
              </div>
            </div>

            <div className="separator mb-3 opacity-75"></div>

            <Menu.Item as="div" className="menu-item px-3">
              <Link to={`/airdrops/${cell.row.original.id}`}>
              <span className="menu-link px-3">
                Düzenle
              </span>
              </Link>
            </Menu.Item>
            <Menu.Item as="div" className="menu-item px-3">
              <span onClick={() => changeStatus(cell.row.original.id,true)} className="menu-link px-3">
                Yayınla Al
              </span>
            </Menu.Item>
            <Menu.Item as="div" className="menu-item px-3 ">
              <span onClick={() => changeStatus(cell.row.original.id,false)}  className="menu-link px-3 text-danger ">
                Sonlandır
              </span>
            </Menu.Item>
            <div className="separator mt-3 opacity-75"></div>
          </Menu.Items>
      </Menu>
      ),
    },
  ];

  return (
    <Wrapper title="Airdrop" path="Anasayfa/Airdrop">
          <AirdropListContainer searchQuery={searchQuery} setSearchQuery={setSearchQuery} data={data} columns={columns} totalPages={pages} pageNumber={page} perPage={perPage} total={total} nextPageFn={() => handleNextPage(page)} prevPageFn={()=>handlePrevPage(page)} />
    </Wrapper>
  )
}
export default AirdropList
