import store from "..";
import {_reset,_get,_edit,_create}  from ".";

export const promotions = {
    reset:() => {
        store.dispatch(_reset())
    },
    get: (params) => {
        store.dispatch(_get(params))
    },
    edit: (data) => {
        store.dispatch(_edit(data))
    },
    create: (data) => {
        store.dispatch(_create(data))
    }
}