import { useEffect } from "react";
import { partners } from "../../stores/partners/actions";
import { useResetError, useResetStatus,useUpdatedKey } from "../../stores/partners/hooks";


import useErrorCode from "../../hooks/useErrorCode";
const ResetApi = ({ destroy,modalData }) => {
  const { e } = useErrorCode();
  const status = useResetStatus();
  const error = useResetError();
  const updatedKey = useUpdatedKey();
  const escFunction = (e) => {
    if (e.key === "Escape") {
      destroy();
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", escFunction, false);

    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, [escFunction]);

  useEffect(()=>{
    return(()=> {
      partners.reset()
    })
  },[])
  

  const resetApiKey = () => {
    partners.resetKey({
      id:modalData.id
    })
  }

  return (
    <div class="form" id="kt_modal_add_customer_form">
      <div class="modal-header" id="kt_modal_add_customer_header">
        <h2 class="fw-bold">API Anahtarını Sıfırla</h2>

        <div
          onClick={destroy}
          id="kt_modal_add_customer_close"
          class="btn btn-icon btn-sm btn-active-icon-primary"
        >
          <i class="ki-duotone ki-cross fs-1">
            <span class="path1"></span>
            <span class="path2"></span>
          </i>
        </div>
      </div>

      <div class="modal-body py-10 px-lg-17">
            {status === "rejected" && (
              <div class="alert alert-danger d-flex align-items-center px-5">
                <div class="d-flex flex-column">
                  <h4 class="mb-1 text-danger">Hata!</h4>

                  <span>{e(error)}</span>
                </div>
              </div>
            )}

            {status === "fulfilled" && (
              <div class="alert alert-success d-flex align-items-center px-5">
                <div class="d-flex flex-column">
                  <h4 class="mb-1 text-success">Başarılı!</h4>

                  <span>API KEY: {updatedKey}</span>
                </div>
              </div>
            )}
            <div
              class="scroll-y me-n7 pe-7"
              id="kt_modal_add_customer_scroll"
              data-kt-scroll="true"
              data-kt-scroll-activate="{default: false, lg: true}"
              data-kt-scroll-max-height="auto"
              data-kt-scroll-dependencies="#kt_modal_add_customer_header"
              data-kt-scroll-wrappers="#kt_modal_add_customer_scroll"
              data-kt-scroll-offset="300px"
            >
          {
            status !== "fulfilled" && (  <p class="fs-4 fw-semibold mb-2 text-center">{modalData.name} adlı iş ortağının API anahtarını sıfırlamak istediğinize emin misiniz?</p>)
          }
            </div>
          </div>

          <div class="modal-footer flex-center">
            <button
              onClick={destroy}
              type="reset"
              id="kt_modal_add_customer_cancel"
              class="btn btn-light me-3"
            >
              İptal
            </button>

            <button
              onClick={() => resetApiKey()}
              type="submit"
              disabled={status === "pending"}
              id="kt_modal_add_customer_submit"
              class="btn btn-primary"
            >
              {status === "pending" ? (
                <span style={{ display: "block" }} class="indicator-progress">
                  Lütfen Bekleyin...
                  <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
              ) : (
                <span class="indicator-label">Sıfırla</span>
              )}
            </button>
          </div>
    </div>
  );
};
export default ResetApi;
