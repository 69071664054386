import { useEffect } from "react";
import { options } from "../../stores/options/actions";
import { useEditOptionStatus, useEditOptionError } from "../../stores/options/hooks";
import { Formik, Form, Field,ErrorMessage } from "formik";
import { createOptionScheme } from "../../validations";
import Input from "../../components/Input";
import useErrorCode from "../../hooks/useErrorCode";

const EditOption = ({ destroy,modalData }) => {
  const { e } = useErrorCode();
  const status = useEditOptionStatus();
  const error = useEditOptionError();

  const escFunction = (e) => {
    if (e.key === "Escape") {
      destroy();
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", escFunction, false);

    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, [escFunction]);

  useEffect(() => {
    return () => {
      options.reset();
    };
  }, []);

  return (
    <div className="form" id="kt_modal_add_customer_form">
      <div className="modal-header" id="kt_modal_add_customer_header">
        <h2 className="fw-bold">Opsiyon Düzenle</h2>

        <div
          onClick={destroy}
          id="kt_modal_add_customer_close"
          className="btn btn-icon btn-sm btn-active-icon-primary"
        >
          <i className="ki-duotone ki-cross fs-1">
            <span className="path1"></span>
            <span className="path2"></span>
          </i>
        </div>
      </div>

      <Formik
        validationSchema={createOptionScheme}
        initialValues={{
          key:modalData.key,
          value:modalData.type === 'boolean' ? (modalData.value === "true" ? "1" : "0"): modalData.value,
          type:modalData.type,
          public:modalData.public === true ? "1" : "0",
          description:modalData.description
        }}
        onSubmit={(values) => {
         options.edit({
          key:values.key,
          description:values.description,
          public:values.public === "1" ? true : false,
          type:values.type,
          value: values.type === 'boolean' ? (values.value === "1" ? true : false) : values.value
         })
        }}
      >
        {({values}) => (
           <Form>
           <div className="modal-body py-10 px-lg-17">
             {status === "rejected" && (
               <div className="alert alert-danger d-flex align-items-center px-5">
                 <div className="d-flex flex-column">
                   <h4 className="mb-1 text-danger">Hata!</h4>
 
                   <span>{e(error)}</span>
                 </div>
               </div>
             )}
 
             {status === "fulfilled" && (
               <div className="alert alert-success d-flex align-items-center px-5">
                 <div className="d-flex flex-column">
                   <h4 className="mb-1 text-success">Başarılı!</h4>
 
                   <span>{"Opsiyon Eklendi"}</span>
                 </div>
               </div>
             )}
             <div
               className="scroll-y me-n7 pe-7"
               id="kt_modal_add_customer_scroll"
              
             >
               <div className="fv-row mb-7">
                 <label className="required fs-6 fw-semibold mb-2">Key</label>
 
                 <Input name="key" placeholder="Key" />
               </div>
               <div className="fv-row mb-7">
                 <label className="required fs-6 fw-semibold mb-2">Tip</label>
 
                 <Field
                   as="select"
                   name="type"
                   className="form-select form-select-solid"
                 
                 >
                   <option value="">Seçiniz</option>
                   <option value="text">Yazı</option>
                   <option value="number">Sayı</option>
                   <option value="decimal">Ondalıklı Sayı</option>
                   <option value="boolean">Boolean</option>
                 </Field>
                 <ErrorMessage
                   component="small"
                   name="type"
                   className="badge badge-light-danger mt-2"
                 />
               </div>
               <div className="fv-row mb-7">
                 <label className="fs-6 fw-semibold mb-2">Değer</label>
             
                {
                 values.type !== "boolean" ?  <Input name="value" placeholder="value" /> : (<>
                  <Field
                   as="select"
                  
                   name="value"
                   className="form-select form-select-solid"
                 
                 >
                   <option value="">Seçiniz</option>
                   <option value="1">Aktif</option>
                   <option value="0">Pasif</option>
                 </Field>
                 <ErrorMessage
                   component="small"
                   name="value"
                   className="badge badge-light-danger mt-2"
                 /></>)
                }
                
               </div>
           
               <div className="fv-row mb-7">
                 <label className="required fs-6 fw-semibold mb-2">
                   Açıklama
                 </label>
 
                 <Input name="description" placeholder="Açıklama" />
                 <div className="text-muted fs-7">
                   Yalnızca admin panelinde gözükür.
                 </div>
               </div>
               <div className="fv-row mb-7">
                 <label className="required fs-6 fw-semibold mb-2">Görüntülenebilir</label>
 
                 <Field
                   as="select"
                  
                   name="public"
                   className="form-select form-select-solid"
                 
                 >
                   <option value="">Seçiniz</option>
                   <option value="1">Evet</option>
                   <option value="0">Hayır</option>
                 </Field>
                 <ErrorMessage
                   component="small"
                   name="public"
                   className="badge badge-light-danger mt-2"
                 />
                  <div className="text-muted fs-7">
                   Kullanıcılar görebilir mi?
                 </div>
               </div>
             </div>
           </div>
 
           <div className="modal-footer flex-center">
             <button
               onClick={destroy}
               type="reset"
               id="kt_modal_add_customer_cancel"
               className="btn btn-light me-3"
             >
               İptal
             </button>
 
             <button
               type="submit"
               disabled={status === "pending"}
               id="kt_modal_add_customer_submit"
               className="btn btn-primary"
             >
               {status === "pending" ? (
                 <span style={{ display: "block" }} className="indicator-progress">
                   Lütfen Bekleyin...
                   <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                 </span>
               ) : (
                 <span className="indicator-label">Düzenle</span>
               )}
             </button>
           </div>
         </Form>
        )}
      </Formik>
    </div>
  );
};
export default EditOption;
