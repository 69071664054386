import { useModals } from "../stores/modal/hooks";
import { modal as modalsRoutes } from "../routes/modal";
import { modal } from "../stores/modal/actions";
import OpacityContent from "../components/animated/opacity";
import AnimatedContent from "../components/animated";
import { AnimatePresence } from "framer-motion";

const Modals = () => {
  const modals = useModals();
  return (
    <>
      <div class="modal-backdrop fade show"></div>
      <OpacityContent
        exitDelay={0.1}
        class="modal fade show"
        style={{ display: "block" }}
      >
        <AnimatePresence>
          <div class="modal-dialog modal-dialog-centered mw-650px">
            {modals.map((modalData) => {
              const current = modalsRoutes.find(
                (m) => m.name === modalData.name
              );
              return (
                <AnimatedContent
                  delay={0.1}
                  key={modalData.name}
                  className="modal-content"
                >
                  <current.element
                    key={modalData.name}
                    destroy={modal.destroy}
                    destroyAll={modal.destroyAll}
                    modalData={modalData?.data}
                  />
                </AnimatedContent>
              );
            })}
          </div>
        </AnimatePresence>
      </OpacityContent>
    </>
  );
};
export default Modals;
