import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from "@reduxjs/toolkit";

import stakesService from "./service";

const stakesAdapter = createEntityAdapter();

export const _get = createAsyncThunk(
  "stake/options",
  async (params, { rejectWithValue }) => {
    try {
      const response = await stakesService.get(params);
      return response;
    } catch (error) {
      const message = error.response.data;

      return rejectWithValue(message);
    }
  }
);

export const _create = createAsyncThunk(
  "stake/options/create",
  async (data, { rejectWithValue }) => {
    try {
      const response = await stakesService.create(data);
      return response;
    } catch (error) {
      const message = error.response.data;

      return rejectWithValue(message);
    }
  }
);

export const _participations = createAsyncThunk(
  "stake/list",
  async (params, { rejectWithValue }) => {
    try {
      const response = await stakesService.participations(params);
      return response;
    } catch (error) {
      const message = error.response.data;

      return rejectWithValue(message);
    }
  }
);

export const _cancel = createAsyncThunk(
  "stake/cancel",
  async (data, { rejectWithValue }) => {
    try {
      const response = await stakesService.cancel(data);
      return response;
    } catch (error) {
      const message = error.response.data;

      return rejectWithValue(message);
    }
  }
);

export const _edit = createAsyncThunk(
  "stake/options/edit",
  async (data, { rejectWithValue }) => {
    try {
      const response = await stakesService.edit(data);
      return response;
    } catch (error) {
      const message = error.response.data;

      return rejectWithValue(message);
    }
  }
);

const initialState = stakesAdapter.getInitialState({
  listTotal: "",
  listPage: "",
  listPages: "",
  listPer_page: "",
  listStatus: "idle",
  listError: null,
  createOptionStatus: "idle",
  createOptionError: null,
  editOptionStatus:"idle",
  editOptionError:null,
  participationsTotal:"",
  participationsPage:"",
  participationsPer_page: "",
  participationsStatus: "idle",
  participationsError: null,
  participationsPages:"",
  cancelStatus:"idle",
  cancelError:null
});

const stakes = createSlice({
  name: "stakes",
  initialState,
  reducers: {
    _reset: (state) => {
      state.listTotal = "";
      state.listPage = "";
      state.listPages = "";
      state.listPer_page = "";
      state.listStatus = "idle";
      state.listError = null;
      state.createOptionStatus = "idle";
      state.createOptionError = null;
      state.editOptionStatus = "idle";
      state.editOptionError = null;
      state.participationsTotal = "";
      state.participationsPage = "";
      state.participationsPages = "";
      state.participationsPer_page = "";
      state.participationsStatus = "idle";
      state.participationsError = null;
      state.cancelStatus = "idle";
      state.cancelError = null
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(_get.pending, (state) => {
        state.listStatus = "pending";
      })
      .addCase(_get.fulfilled, (state, action) => {
        state.listStatus = "fulfilled";
        stakesAdapter.setAll(state, action.payload.items);
        state.listTotal = action.payload.total;
        state.listPage = action.payload.page;
        state.listPages = action.payload.pages;
        state.listPer_page = action.payload.per_page;
      })
      .addCase(_get.rejected, (state, action) => {
        state.listStatus = "rejected";
        state.listError = action.payload.errorcode;
      })
      .addCase(_create.pending, (state) => {
        state.createOptionStatus = "pending";
      })
      .addCase(_create.fulfilled, (state, action) => {
        state.createOptionStatus = "fulfilled";
        stakesAdapter.setOne(state, action.payload);
      
      })
      .addCase(_create.rejected, (state, action) => {
        state.createOptionStatus = "rejected";
        state.createOptionError = action.payload.errorcode;
      })
      .addCase(_edit.pending, (state) => {
        state.editOptionStatus = "pending";
      })
      .addCase(_edit.fulfilled, (state, action) => {
        state.editOptionStatus = "fulfilled";
        stakesAdapter.upsertOne(state, action.payload);
      
      })
      .addCase(_edit.rejected, (state, action) => {
        state.editOptionStatus = "rejected";
        state.editOptionError = action.payload.errorcode;
      })
      .addCase(_participations.pending, (state) => {
        state.participationsStatus = "pending";
      })
      .addCase(_participations.fulfilled, (state, action) => {
        state.participationsStatus = "fulfilled";
        stakesAdapter.setAll(state, action.payload.items);
        state.participationsPage = action.payload.page;
        state.participationsPages = action.payload.pages;
        state.participationsTotal = action.payload.total
        state.participationsPer_page = action.payload.per_page;
      
      })
      .addCase(_participations.rejected, (state, action) => {
        state.participationsStatus = "rejected";
        state.participationsError = action.payload.errorcode;
      })
      .addCase(_cancel.pending, (state) => {
        state.cancelStatus = "pending";
      })
      .addCase(_cancel.fulfilled, (state, action) => {
        state.cancelStatus = "fulfilled";
        stakesAdapter.upsertOne(state, action.payload.items);
      
      })
      .addCase(_cancel.rejected, (state, action) => {
        state.cancelStatus = "rejected";
        state.cancelError = action.payload.errorcode;
      });
  },
});

export const { _reset } = stakes.actions;
export default stakes.reducer;

export const {
  selectAll: selectAllStakes,

  selectById: selectStakesById,
} = stakesAdapter.getSelectors((state) => state.stakes);
