import axios from 'axios';

const instance = axios.create({
  baseURL: 'https://api.izmirtoken.io/admin', 
});


instance.interceptors.request.use(
  config => {
    const token = localStorage.getItem('access_token');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);


instance.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if (error.response) {
      switch (error.response.status) {
        case 401:
          // Örneğin, kullanıcı oturumu sona erdiyse, oturum açma sayfasına yönlendirme yapılabilir
          break;
        case 403:
          window.location.href = '/login';
          localStorage.removeItem('access_token');
          break;
      }
    } else if (error.request) {
     
      console.error("Server did not respond.", error.request);
    } else {

      console.error("Error setting up request", error.message);
    }

    return Promise.reject(error);
   
  }
);

export default instance;