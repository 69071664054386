import instance from '../../utils/api/instance'

const get = async() => {
    const response = await instance.get("merchants")
    if(response.status === 200){
        return response.data;
    }

    return Promise.reject(response.data);
}

const post = async(data) => {
    const response = await instance.post("merchants",data)
    if(response.status === 200){
        return response.data;
    }

    return Promise.reject(response.data);
}


const patch = async(data) => {
    const response = await instance.patch("merchants",data)
    if(response.status === 200){
        return response.data;
    }

    return Promise.reject(response.data);
}


const partnersService = {
    get,
    patch,
    post
};

export default partnersService;