import instance from '../../utils/api/instance'

const get = async(params) => {
    const response = await instance.get("promocodes",{params:params})
    if(response.status === 200){
        return response.data;
    }

    return Promise.reject(response.data);
}
const edit = async(data) => {
    const response = await instance.patch("promocodes",data)
    if(response.status === 200){
        return response.data;
    }

    return Promise.reject(response.data);
}




const promocodesService = {
    get,
    edit
};

export default promocodesService;