import store from "..";
import {_reset,_get}  from ".";

export const dashboard = {
    reset:() => {
        store.dispatch(_reset())
    },
    get: () => {
        store.dispatch(_get())
    }
}