import store from "..";
import {_reset,_get,_edit}  from ".";

export const promocodesclaims = {
    reset:() => {
        store.dispatch(_reset())
    },
    get: (params) => {
        store.dispatch(_get(params))
    },
   
   
}