import { useEffect, useState } from "react";
import { users } from "../../stores/users/actions";
import Decimal from "decimal.js";
const AdjustBalance = ({ destroy, modalData }) => {
  const [newBalance, setNewBalance] = useState();
  const escFunction = (e) => {
    if (e.key === "Escape") {
      destroy();
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", escFunction, false);

    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, [escFunction]);

  const editUser = () => {
    users.edit({
      id: modalData?.id,
      old_balance: modalData?.balance,
      balance: newBalance,
    });
    destroy();
  };

  return (
    <div className="form" id="kt_modal_add_customer_form">
      <div className="modal-header" id="kt_modal_add_customer_header">
        <h2 className="fw-bold">Bakiye Düzenle</h2>

        <div
          onClick={destroy}
          id="kt_modal_add_customer_close"
          className="btn btn-icon btn-sm btn-active-icon-primary"
        >
          <i className="ki-duotone ki-cross fs-1">
            <span className="path1"></span>
            <span className="path2"></span>
          </i>
        </div>
      </div>

      <div
        className="modal-body scroll-y mx-5 mx-xl-15 my-7"
        data-select2-id="select2-data-17-tqyw"
      >
        <div className="d-flex text-center mb-9">
          <div className="w-50 border border-dashed border-gray-300 rounded mx-2 p-4">
            <div className="fs-6 fw-semibold mb-2 text-muted">Bakiye</div>
            <div
              className="fs-2 fw-bold"
              kt-modal-adjust-balance="current_balance"
            >
              {Decimal(modalData?.balance).toNumber()}
              <span class="text-muted fs-4 fw-semibold"> IZMR</span>
            </div>
          </div>
          <div className="w-50 border border-dashed border-gray-300 rounded mx-2 p-4">
            <div className="fs-6 fw-semibold mb-2 text-muted">Yeni Bakiye</div>
            <div className="fs-2 fw-bold" kt-modal-adjust-balance="new_balance">
              {newBalance ? newBalance : "--"}
              <span class="text-muted fs-4 fw-semibold"> IZMR</span>
            </div>
          </div>
        </div>

        <form id="kt_modal_adjust_balance_form" className="form" action="#">
          <div className="fv-row mb-7">
            <label className="required fs-6 fw-semibold form-label mb-2">
              Miktar
            </label>

            <input
              id="kt_modal_inputmask"
              type="text"
              className="form-control form-control-solid"
              name="amount"
              value={newBalance}
              onChange={(e) => setNewBalance(e.target.value)}
            />
          </div>
        </form>
      </div>

      <div className="modal-footer flex-center">
        <button
          onClick={destroy}
          type="reset"
          id="kt_modal_add_customer_cancel"
          className="btn btn-light me-3"
        >
          İptal
        </button>

        <button
          onClick={() => editUser()}
          type="submit"
          id="kt_modal_add_customer_submit"
          className="btn btn-primary"
        >
          <span className="indicator-label">Kaydet</span>
          <span className="indicator-progress">
            Lütfen Bekleyin...
            <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
          </span>
        </button>
      </div>
    </div>
  );
};
export default AdjustBalance;
