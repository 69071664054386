import { createSlice,createAsyncThunk,createEntityAdapter } from "@reduxjs/toolkit";

import usersService from "./service";

const usersAdapter = createEntityAdapter();

export const _get = createAsyncThunk("users/get", async(params, {rejectWithValue}) => {
    try {
        const response = await usersService.get(params);
        return response
    } catch (error) {
         const message = error.response.data
       
        return rejectWithValue(message);
    }
});

export const _edit = createAsyncThunk("users/edit", async(data, {rejectWithValue}) => {
    try {
        const response = await usersService.edit(data);
       
        return response

    } catch (error) {
         const message = error.response.data
     
        return rejectWithValue(message);
    }
});


const initialState = usersAdapter.getInitialState({
    total: 0,
    page: 1,
    pages: 1,
    per_page: 10,
    status: 'idle',
    error: null,
    editStatus:'idle',
    editError:null
});


const users = createSlice({
    name:"users",
    initialState,
    reducers:{
        _reset: (state) => {
            state.status = 'idle';
            state.error = "";
            state.editStatus = "idle";
            state.editError = ""
        }
    },
    extraReducers:(builder) => {
        builder
        .addCase(_get.pending, (state) => {
            state.status = 'pending'
        })
        .addCase(_get.fulfilled, (state, action) => {
            state.status = 'fulfilled';
            usersAdapter.setAll(state, action.payload.items);
            state.total = action.payload.total;
            state.page = action.payload.page;
            state.pages = action.payload.pages;
            state.per_page = action.payload.per_page;
        })
        .addCase(_get.rejected, (state,action) => {
            state.status = "rejected"
            state.error = action.payload.errorcode
        })
        .addCase(_edit.pending, (state) => {
            state.editStatus = 'pending'
        })
        .addCase(_edit.fulfilled, (state,action) => {
            state.editStatus = "fulfilled";
            usersAdapter.upsertOne(state,action.payload)
        })
        .addCase(_edit.rejected,(state,action)=>{
            state.status = "rejected"
            state.editError = action.payload
        } )
    }
})

export const {_reset} = users.actions;
export default users.reducer

export const {
    selectAll: selectAllUsers,
    selectById: selectUserById,
    selectIds: selectUserIds,
   
} = usersAdapter.getSelectors(state => state.users);
