import {useSelector} from "react-redux";
import { selectAllUsers, selectUserById } from ".";
export const useStatus = () => useSelector(state=> state.users.status);
export const useError = () => useSelector(state=> state.users.error);
export const useData = () => useSelector(selectAllUsers);
export const useUserById = (userId) => useSelector(state => selectUserById(state,userId));
export const useEditError = () => useSelector(state => state.users.editError);
export const useEditStatus = () => useSelector(state => state.users.editStatus);
export const usePage = () => useSelector(state=>state.users.page);
export const usePages = () => useSelector(state=>state.users.pages);
export const useTotal = () => useSelector(state=>state.users.total);
export const usePerPage = () => useSelector(state=>state.users.per_page);