import { createSlice,createAsyncThunk,createEntityAdapter } from "@reduxjs/toolkit";

import partnersService from "./service";

const partnersAdapter = createEntityAdapter();

export const _get = createAsyncThunk("partners/get", async(_, {rejectWithValue}) => {
    try {
        const response = await partnersService.get();
        return response
    } catch (error) {
         const message = error.response.data
       
        return rejectWithValue(message);
    }
});

export const _create = createAsyncThunk("partners/create", async(data, {rejectWithValue}) => {
    try {
        const response = await partnersService.post(data);
       
        return response

    } catch (error) {
         const message = error.response.data
     
        return rejectWithValue(message);
    }
});

export const _resetKey = createAsyncThunk("partners/reset", async(data, {rejectWithValue}) => {
    try {
        const response = await partnersService.patch(data);
       
        return response

    } catch (error) {
         const message = error.response.data
     
        return rejectWithValue(message);
    }
});


const initialState = partnersAdapter.getInitialState({
   
    status: 'idle',
    error: null,
    addStatus:'idle',
    addError:null,
    resetStatus:"idle",
    resetError:null,
    updatedKey:null
});


const partners = createSlice({
    name:"partners",
    initialState,
    reducers:{
        _reset: (state) => {
            state.status = 'idle';
            state.error = "";
            state.addStatus = "idle";
            state.addError = "";
            state.resetStatus = "idle";
            state.resetError = "null";
            state.updatedKey = null;
        }
    },
    extraReducers:(builder) => {
        builder
        .addCase(_get.pending, (state) => {
            state.status = 'pending'
        })
        .addCase(_get.fulfilled, (state, action) => {
            state.status = 'fulfilled';
            partnersAdapter.setAll(state, action.payload);
          
        })
        .addCase(_get.rejected, (state,action) => {
            state.status = "rejected"
            state.error = action.payload.errorcode
        })
        .addCase(_create.pending, (state) => {
            state.addStatus = 'pending'
        })
        .addCase(_create.fulfilled, (state, action) => {
            state.addStatus = 'fulfilled';
            partnersAdapter.addOne(state, action.payload);
          
        })
        .addCase(_create.rejected, (state,action) => {
            state.addStatus = "rejected"
            state.addError = action.payload.errorcode
        })
        .addCase(_resetKey.pending, (state) => {
            state.resetStatus = 'pending'
        })
        .addCase(_resetKey.fulfilled, (state, action) => {
            state.resetStatus = 'fulfilled';
            partnersAdapter.upsertOne(state, action.payload);
            state.updatedKey = action.payload.apikey
          
        })
        .addCase(_resetKey.rejected, (state,action) => {
            state.resetStatus = "rejected"
            state.resetError = action.payload.errorcode
        })
    }
})

export const {_reset} = partners.actions;
export default partners.reducer

export const {
    selectAll: selectAllPartners,
   
} = partnersAdapter.getSelectors(state => state.partners);
