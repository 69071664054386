import { createSlice,createAsyncThunk } from "@reduxjs/toolkit";
import dashboardService from "./service";


export const _get = createAsyncThunk("dashboard/get", async(_, {rejectWithValue}) => {
    try {
        const response = await dashboardService.get();
        return response
    } catch (error) {
         const message = error.response.data
       
        return rejectWithValue(message);
    }
});

const initialState = {
    data:"",
    status:'idle',
    error:"",
};


const dashboard = createSlice({
    name:"dashboard",
    initialState,
    reducers:{
        _reset: (state) => {
            state.status = 'idle';
            state.error = "";
        }
    },
    extraReducers:(builder) => {
        builder
        .addCase(_get.pending, (state) => {
            state.status = 'pending'
        })
        .addCase(_get.fulfilled, (state,action) =>{
            state.status = "fulfilled";
            state.data = action.payload;
        })
        .addCase(_get.rejected, (state,action) => {
            state.status = "rejected"
            state.error = action.payload.errorcode
        })
    }
})

export const {_reset} = dashboard.actions;
export default dashboard.reducer
