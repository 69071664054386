import store from "..";
import {_reset,_get,_edit,_create,_participations,_changeStatus}  from ".";

export const airdrops = {
    reset:() => {
        store.dispatch(_reset())
    },
    get: (params) => {
        store.dispatch(_get(params))
    },
    edit: (data) => {
        store.dispatch(_edit(data))
    },
    create: (data) => {
        store.dispatch(_create(data))
    },
    participations: (params) => {
        store.dispatch(_participations(params))
    },
    changeStatus: (data) => {
        store.dispatch(_changeStatus(data))
    }
}