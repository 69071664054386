import instance from '../../utils/api/instance'

const get = async() => {
    const response = await instance.get("dashboard")
    if(response.status === 200){
        return response.data;
    }

    return Promise.reject(response.data);
}


const dashboardService = {
    get
};

export default dashboardService;