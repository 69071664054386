import instance from '../../utils/api/instance'

const participations = async(params) => {
    const response = await instance.get("airdrop-participations",{params:params})
    if(response.status === 200){
        return response.data;
    }

    return Promise.reject(response.data);
}

const editParticipations = async(data) => {
    const response = await instance.patch("airdrop-participations",data)
    if(response.status === 200){
        return response.data;
    }

    return Promise.reject(response.data);
}

const get = async(params) => {
    const response = await instance.get("airdrop",{params:params})
    if(response.status === 200){
        return response.data;
    }

    return Promise.reject(response.data);
}

const edit = async(data) => {
    const response = await instance.patch("airdrop",data)
    if(response.status === 200){
        return response.data;
    }

    return Promise.reject(response.data);
}

const create = async(data) => {
    const response = await instance.post("airdrop",data)
    if(response.status === 200){
        return response.data;
    }

    return Promise.reject(response.data);
}


const airdropsService = {
    get,
    edit,
    create,
    participations,
    editParticipations
};

export default airdropsService;