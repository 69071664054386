import { useEffect } from "react"
import Wrapper from "../../../components/Wrapper"
import AirdropCreateContainer from "../../../containers/Airdrops/Create"
import {partners} from "../../../stores/partners/actions"
import {useData} from "../../../stores/partners/hooks"
const AirdropCreate = () => {
  const data = useData()
  useEffect(()=>{
    partners.get()
  },[])
  return (
    <Wrapper title="Airdrop" path="Anasayfa/Airdrop/Oluştur">
        <AirdropCreateContainer data={data}/>
    </Wrapper>
  )
}
export default AirdropCreate