import {motion} from "framer-motion";


export default function OpacityContent({children, delay, exitDelay, ...props}) {
	return (
		<motion.section
			initial={{opacity: 0}}
			animate={{opacity: 1, transition: {delay}}}
			exit={{opacity: 0, transition: {delay: exitDelay}}}
			{...props}
		>
			{children}
		</motion.section>
	)
}

