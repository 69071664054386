import instance from '../../utils/api/instance'

const get = async(params) => {
    const response = await instance.get("promos",{params:params})
    if(response.status === 200){
        return response.data;
    }

    return Promise.reject(response.data);
}


const promocodesclaimsService = {
    get
};

export default promocodesclaimsService;
