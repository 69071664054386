import { useEffect } from "react";
import bg11 from "../../assets/media/auth/bg11.png";
import logo from "../../assets/media/logos/logo.png";
import { Formik, Form } from "formik";
import { loginScheme } from "../../validations";
import Input from "../../components/Input";
import { login } from "../../stores/login/actions";
import { useResponse, useError, useStatus } from "../../stores/login/hooks";
import useErrorCode from "../../hooks/useErrorCode";
import { useNavigate } from "react-router-dom";
const LoginContainer = () => {
  const response = useResponse();
  const error = useError();
  const status = useStatus();
  const { e } = useErrorCode();
  const navigate = useNavigate();

  useEffect(() => {
    return () => {
      login.reset();
    }
  },[])

  useEffect(() => {
    if (status === "fulfilled") {
      setTimeout(() => {
        navigate("/");
      }, 1500);
    }
   
  }, [status]);

  return (
    <div className="d-flex flex-column flex-lg-row flex-column-fluid">
      <div className="d-block d-lg-none mx-auto py-20">
        <img alt="Logo" src={logo} className="h-25px" />
      </div>

      <div className="d-flex flex-column flex-column-fluid flex-center w-lg-50 p-10">
        <div className="d-flex justify-content-between flex-column-fluid flex-column w-100 mw-450px">
          <div className="d-flex flex-stack py-2">
            <div className="me-2"></div>
          </div>

          <div className="py-20">
            <Formik
              validationSchema={loginScheme}
              initialValues={{
                email: "",
                password: "",
                totp: "",
              }}
              onSubmit={(values) => {
                login.post({
                  email: values.email,
                  password: values.password,
                  totp: values.totp,
                });
              }}
            >
              <Form className="form w-100">
                {status === "rejected" && (
                  <div class="alert alert-danger d-flex align-items-center p-5">
                    <div class="d-flex flex-column">
                      <h4 class="mb-1 text-danger">Hata!</h4>

                      <span>{e(error)}</span>
                    </div>
                  </div>
                )}

                {status === "fulfilled" && (
                  <div class="alert alert-success d-flex align-items-center p-5">
                    <div class="d-flex flex-column">
                      <h4 class="mb-1 text-success">Başarılı!</h4>

                      <span>{e(response)}</span>
                    </div>
                  </div>
                )}

                <div className="card-body">
                  <div className="text-start mb-10">
                    <h1 className="text-dark mb-3 fs-3x">Giriş Yap </h1>
                  </div>

                  <div className="fv-row mb-8">
                    <Input name="email" placeholder="E-Posta" type="text" />
                  </div>

                  <div className="fv-row mb-7">
                    <Input
                      name="password"
                      placeholder="Şifre"
                      type="password"
                    />
                  </div>
                  <div className="fv-row mb-7">
                    <Input name="totp" placeholder="TOTP" type="text" />
                  </div>

                  <div className="d-flex flex-stack">
                    <button
                      type="submit"
                      disabled={status === "pending"}
                      className="btn btn-primary me-2 flex-shrink-0"
                    >
                      {status === "pending" ? (
                        <span
                          style={{ display: "block" }}
                          className="indicator-progress"
                        >
                          <span data-kt-translate="general-progress">
                            Lütfen Bekleyiniz...
                          </span>
                          <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </span>
                      ) : (
                        <span className="indicator-label">Giriş Yap</span>
                      )}
                    </button>
                  </div>
                </div>
              </Form>
            </Formik>
          </div>

          <div className="m-0"></div>
        </div>
      </div>

      <div
        className="d-none d-lg-flex flex-lg-row-fluid w-50 bgi-size-cover bgi-position-y-center bgi-position-x-start bgi-no-repeat"
        style={{ backgroundImage: `url(${bg11})` }}
      ></div>
    </div>
  );
};
export default LoginContainer;
