import { useEffect } from "react";
import { stakes } from "../../stores/stakes/actions";
import { useEditOptionStatus, useEditOptionError } from "../../stores/stakes/hooks";
import { Formik, Form, Field,ErrorMessage } from "formik";
import { addStakeOptionScheme } from "../../validations";
import Input from "../../components/Input";
import useErrorCode from "../../hooks/useErrorCode";
const EditStakeOption = ({ destroy,modalData }) => {
  const { e } = useErrorCode();
  const status = useEditOptionStatus();
  const error = useEditOptionError();

  const escFunction = (e) => {
    if (e.key === "Escape") {
      destroy();
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", escFunction, false);

    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, [escFunction]);

  useEffect(() => {
    return () => {
      stakes.reset();
    };
  }, []);

  return (
    <div className="form" id="kt_modal_add_customer_form">
      <div className="modal-header" id="kt_modal_add_customer_header">
        <h2 className="fw-bold"> Stake Opsiyonu Düzenle</h2>

        <div
          onClick={destroy}
          id="kt_modal_add_customer_close"
          className="btn btn-icon btn-sm btn-active-icon-primary"
        >
          <i className="ki-duotone ki-cross fs-1">
            <span className="path1"></span>
            <span className="path2"></span>
          </i>
        </div>
      </div>

      <Formik
        validationSchema={addStakeOptionScheme}
        initialValues={{
          min_amount:modalData.min_amount,
          max_amount:modalData.max_amount === null ? "" : modalData.max_amount,
          release_days:modalData.release_days,
          reward_percent:modalData.reward_percent,
          enabled:modalData.enabled === true ? "1" : "0"
        }}
        onSubmit={(values) => {
          stakes.edit({
            id:modalData.id,
            min_amount:values.min_amount,
            max_amount:values.max_amount === "" ? null : values.max_amount,
            release_days:values.release_days,
            reward_percent:values.reward_percent,
            enabled:values.enabled === "1" ? true:false
          });
        }}
      >
        <Form>
          <div className="modal-body py-10 px-lg-17">
            {status === "rejected" && (
              <div className="alert alert-danger d-flex align-items-center px-5">
                <div className="d-flex flex-column">
                  <h4 className="mb-1 text-danger">Hata!</h4>

                  <span>{e(error)}</span>
                </div>
              </div>
            )}

            {status === "fulfilled" && (
              <div className="alert alert-success d-flex align-items-center px-5">
                <div className="d-flex flex-column">
                  <h4 className="mb-1 text-success">Başarılı!</h4>

                  <span>{"Opsiyon Eklendi"}</span>
                </div>
              </div>
            )}
            <div
              className="scroll-y me-n7 pe-7"
              id="kt_modal_add_customer_scroll"

            >
              <div className="fv-row mb-7">
                <label className="required fs-6 fw-semibold mb-2">Min Miktar</label>

                <Input name="min_amount" placeholder="Min Miktar" />
              </div>
              <div className="fv-row mb-7">
                <label className="fs-6 fw-semibold mb-2">Max Miktar</label>

                <Input name="max_amount" placeholder="Max Miktar" />
                <div className="text-muted fs-7">
                  Opsiyonel
                </div>
              </div>
              <div className="fv-row mb-7">
                <label className="required fs-6 fw-semibold mb-2">Stake Yüzdesi</label>

                <Input name="reward_percent" placeholder="%" />
              </div>
              <div className="fv-row mb-7">
                <label className="required fs-6 fw-semibold mb-2">
                  Kilit Açılma Günü
                </label>

                <Input name="release_days" placeholder="5" />
                <div className="text-muted fs-7">
                  Kaç (aritmetik) gün sonra kilit açılacak
                </div>
              </div>
              <div className="fv-row mb-7">
                <label className="required fs-6 fw-semibold mb-2">Durum</label>

                <Field
                  as="select"

                  name="enabled"
                  className="form-select form-select-solid"

                >
                  <option value="">Seçiniz</option>
                  <option value="1">Aktif</option>
                  <option value="0">Pasif</option>
                </Field>
                <ErrorMessage
                  component="small"
                  name="enabled"
                  className="badge badge-light-danger mt-2"
                />
              </div>
            </div>
          </div>

          <div className="modal-footer flex-center">
            <button
              onClick={destroy}
              type="reset"
              id="kt_modal_add_customer_cancel"
              className="btn btn-light me-3"
            >
              İptal
            </button>

            <button
              type="submit"
              disabled={status === "pending"}
              id="kt_modal_add_customer_submit"
              className="btn btn-primary"
            >
              {status === "pending" ? (
                <span style={{ display: "block" }} className="indicator-progress">
                  Lütfen Bekleyin...
                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
              ) : (
                <span className="indicator-label">Düzenle</span>
              )}
            </button>
          </div>
        </Form>
      </Formik>
    </div>
  );
};
export default EditStakeOption;
