import instance from '../../utils/api/instance'

const login = async(data) => {
    const response = await instance.post("login",data)
    if(response.status === 200){
        localStorage.setItem("access_token",response.data.access_token);
        return response.data;
    }

    return Promise.reject(response.data);
}

const logout = async() => {
    localStorage.removeItem("access_token")
}


const loginService = {
    login,
    logout
};

export default loginService;