import { useEffect } from "react"
import Wrapper from "../../components/Wrapper"
import TransactionsContainer from "../../containers/Transactions"
import { transactions } from "../../stores/transactions/actions";
import { useData,usePage,usePages,useTotal,usePerPage } from "../../stores/transactions/hooks";
import { useSearchParams,Link } from "react-router-dom";
import Decimal from "decimal.js";
import {modal} from "../../stores/modal/actions"

const Transactions = () => {
  let [searchParams, setSearchParams] = useSearchParams();
  const data = useData();

  const page = usePage();
  const pages = usePages();
  const total = useTotal();
  const perPage = usePerPage();

    useEffect(()=>{
        transactions.get({page:searchParams.get("page")})
    },[searchParams])

    const handleNextPage = (page) => {
      setSearchParams({page:page+1})
    }

    const handlePrevPage = (page) => {
      setSearchParams({page:page-1})
    }



    const transactionType = (type) => {
        switch (type) {
            case "airdrop":
                return "Airdrop"
            case "stake":
                return "Stake"
            case "withdraw":
                return "Çekim"
            case "deposit":
                return "Yatırım"
            case "stake_release":
                return "Stake Kazancı"
            default:
                return "Bilinmeyen"
        }
    }

    const transactionStatus = (type) => {
        switch (type) {
            case "created":
                return (<span class="btn btn-sm btn-light-warning btn-color-warning btn-active-light-warning px-4 py-2 me-4">Oluşturuldu</span>)
            case "success":
                return (<span class="btn btn-sm btn-light-success btn-color-success btn-active-light-success px-4 py-2 me-4">Başarılı</span>)
            case "failed":
                return (<span class="btn btn-sm btn-light-danger btn-color-danger btn-active-light-danger px-4 py-2 me-4">Başarısız</span>)
            default:
                return "Bilinmeyen"
        }
    }

    const columns = [
        {
            header: "ID",
            accessorKey: "id",
          },
        {
          header: "Ad Soyad",
          cell: (cell) => (<Link to={`/users/${cell.row.original.user_id}`} className="text-gray-600 text-hover-primary mb-1">{cell.row.original.user.first_name} {cell.row.original.user.last_name}</Link>),
        },
        {
          header: "Miktar",
          accessorFn: (row) => `${Decimal(row.amount).toNumber()} IZMR`,
        },
        {
          header: "Komisyon",
          accessorFn: (row) => `${Decimal(row.fee_amount).toNumber()} IZMR`,
        },
        {
            header: "Tip",
            accessorFn:(row) => `${transactionType(row.type)}`
          },
        {
          header: "Durum",
          cell:(cell) => (transactionStatus(cell.row.original.status))
        },
        {
          header: "Detay",
          cell: (cell) => (
            <div
              onClick={() => modal.append("modal.detail-transaction",{...cell.row.original})}
              className="btn btn-sm btn-light btn-flex btn-center btn-active-light-primary"
              data-kt-menu-trigger="click"
              data-kt-menu-placement="bottom-end"
            >

              <i className="ki-duotone ki-eye fs-3 ms-1">
                <span className="path1"></span>
                <span className="path2"></span>
                <span className="path3"></span>
              </i>
            </div>
          ),
        },


      ];

  return (
    <Wrapper title="Transferler" path="Anasayfa/Transferler">
        <TransactionsContainer searchable={false} data={data} columns={columns} totalPages={pages} pageNumber={page} perPage={perPage} total={total} nextPageFn={() => handleNextPage(page)} prevPageFn={()=>handlePrevPage(page)}/>
    </Wrapper>
  )
}
export default Transactions
