import { useState } from "react";

import {
  useReactTable,
  getCoreRowModel,
  flexRender,
  getFilteredRowModel,
} from "@tanstack/react-table";

const Table = ({ searchable=true,searchQuery,setSearchQuery,data, columns, action = false, actionFn, actionText,perPage = 1,totalPages = 1,pageNumber = 1,total,nextPageFn,prevPageFn }) => {

  const canPrevPage = pageNumber > 1;
  const canNextPage = total > perPage * pageNumber;

  const [filtering, setFiltering] = useState("");
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    manualPagination:true,
    //getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    state: { globalFilter: filtering },
    onGlobalFilterChange: setFiltering,
  });


  return (
    <div class="card table-responsive">
      <div class="card-header border-0 pt-6 ">
        {
          searchable && (
                <div className="card-title">
                  <div className="d-flex align-items-center position-relative my-1">
                    <i className="ki-duotone ki-magnifier fs-3 position-absolute ms-5">
                      <span className="path1"></span>
                      <span className="path2"></span>
                    </i>
                    <input
                        type="text"
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        className="form-control form-control-solid w-250px ps-12"
                        placeholder="Ara"
                    />
                  </div>
                </div>
            )
        }
        {action && (
            <div className="card-toolbar">
              <div className="d-flex justify-content-center">
                <a onClick={actionFn} className="btn btn-primary">
                  {actionText}
                </a>
              </div>
            </div>
        )}
      </div>
      <div className="card-body pt-0">
        <table
            className="table align-middle align-content-center justify-content-center table-row-dashed fs-6 gy-5"
            id="kt_customers_table"
        >
          <thead>
          {table.getHeaderGroups().map((headerGroup) => (
              <tr
                key={headerGroup.id}
                className="text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0"
              >
                {headerGroup.headers.map((header) => (
                  <th key={header.id}>
                    {flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody className="fw-semibold text-gray-600">
            {table.getRowModel().rows.map((row) => (
              <tr key={row.id}>
                {row.getVisibleCells().map((cell) => (
                  <td key={cell.id}>
                    <a className="text-gray-600 text-hover-primary mb-1">
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </a>
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
        <div className="row">
          {
            /*
            <div className="col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start">
            <div className="dataTables_length">
              <label>
                <select
                  name="kt_customers_table_length"
                  className="form-select form-select-sm form-select-solid"
                >
                  <option value="10">10</option>
                  <option value="25">25</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
              </label>
            </div>
          </div>
            */
          }
          <div className="col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end">
            <div className="dataTables_paginate paging_simple_numbers">

              <ul className="pagination">
                <li

                  className={`paginate_button page-item previous ${
                    !canPrevPage && "disabled"
                  }`}
                >
                  <button
                   onClick={prevPageFn}
                    disabled={!canPrevPage}
                    aria-controls="kt_customers_table"
                    tabindex="0"
                    className="page-link"
                  >
                    <i className="previous"></i>
                  </button>
                </li>

                <li style={{display:"flex", alignItems:"center"}} className="paginate_button page-item active">
                <p className="text-gray-800 text-center m-0">{`${
                pageNumber
              } / ${totalPages}`}</p>
               </li>

                <li

                  className={`paginate_button page-item previous ${
                    !canNextPage && "disabled"
                  }`}
                >
                  <button
                   onClick={nextPageFn}
                    disabled={!canNextPage}
                    aria-controls="kt_customers_table"
                    tabindex="0"
                    className="page-link"
                  >
                    <i className="next"></i>
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Table;

