import { useEffect } from "react";
import Wrapper from "../../components/Wrapper";
import HomeContainer from "../../containers/Home";
import { dashboard } from "../../stores/dashboard/actions";
import { useData } from "../../stores/dashboard/hooks";
const Home = () => {

  useEffect(() => {
    dashboard.get();
  }, []);

  const data = useData();

  return (
    <Wrapper title="Anasayfa" path="IZMR Admin/Anasayfa">
      <HomeContainer data={data} />
    </Wrapper>
  );
};
export default Home;
