import {useSelector} from "react-redux";
import { selectAllPromocodes, selectPromocodesById } from ".";
export const useStatus = () => useSelector(state=> state.promocodes.status);
export const useError = () => useSelector(state=> state.promocodes.error);
export const useData = () => useSelector(selectAllPromocodes);
export const useEditStatus = () => useSelector(state=> state.promocodes.editStatus);
export const useEditError = () => useSelector(state=> state.promocodes.editError);
export const usePage = () => useSelector(state=>state.promocodes.page);
export const usePages = () => useSelector(state=>state.promocodes.pages);
export const useTotal = () => useSelector(state=>state.promocodes.total);
export const usePerPage = () => useSelector(state=>state.promocodes.per_page);
export const useSelectPromotionById = (id) => useSelector(state => selectPromocodesById(state,id))