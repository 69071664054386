import Table from "../../../components/Table";

const ParticipationsContainer = ({setSearchQuery,searchQuery,data, columns,totalPages,pageNumber,perPage,total,nextPageFn,prevPageFn}) => {
  return (
    <div class="row g-5 g-xl-8">
      <Table searchQuery={searchQuery} setSearchQuery={setSearchQuery} data={data} columns={columns} totalPages={totalPages} pageNumber={pageNumber} perPage={perPage} total={total} nextPageFn={nextPageFn} prevPageFn={prevPageFn} />
    </div>
  );
};
export default ParticipationsContainer;
