import { configureStore } from "@reduxjs/toolkit";

// reducers
import app from "./app";
import modal from './modal'
import login from './login'
import dashboard from "./dashboard";
import users from "./users"
import partners from "./partners";
import promotions from "./promotions";
import airdrops from "./airdrops";
import stakes from "./stakes";
import transactions from "./transactions";
import options from "./options";
import promocodes from "./promocodes";
import promocodesclaims from "./promocodesclaims"
import airdropParticipations from "./airdropParticipations";


const store = configureStore({
  reducer: {
    app,
    modal,
    login,
    dashboard,
    users,
    partners,
    promotions,
    airdrops,
    stakes,
    transactions,
    options,
    promocodes,
    promocodesclaims,
    airdropParticipations
  }
})

export default store