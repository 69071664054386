import {useSelector} from "react-redux";
import { selectAllOptions,selectOptionsById} from ".";

export const useOptionsStatus = () => useSelector(state=> state.options.listStatus);
export const useOptionsError = () => useSelector(state=> state.options.listError);
export const useCreateOptionStatus = () => useSelector(state=> state.options.createOptionStatus);
export const useCreateOptionError = () => useSelector(state=> state.options.createOptionError);
export const useEditOptionStatus = () => useSelector(state=> state.options.editOptionStatus);
export const useEditOptionError = () => useSelector(state=> state.options.editOptionError);
export const useOptionsData = () => useSelector(state => state.options.optionsData);
export const useOptionById = (id) => useSelector(state => selectOptionsById(state,id))

