import { useEffect } from "react"
import Wrapper from "../../../components/Wrapper"
import PromotionCreateContainer from "../../../containers/Promotions/Create"
import {partners} from "../../../stores/partners/actions"
import {useData} from "../../../stores/partners/hooks"
const PromotionCreate = () => {
  const data = useData()
  useEffect(()=>{
    partners.get()
  },[])
  return (
    <Wrapper title="Promosyonlar" path="Anasayfa/Promosyonlar/Oluştur">
        <PromotionCreateContainer data={data}/>
    </Wrapper>
  )
}
export default PromotionCreate